import React, { Component } from "react";
import './slideshow.css';
import SLIDESHOW_STR from '../../../strings/slideshow_strings';
import {ReactComponent as IconNavRight} from './slideshow_nav_right.svg';
import {ReactComponent as IconNavLeft} from './slideshow_nav_left.svg';

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      currentSlide: 0,
      slides: props.data,
    };
  }

  nextSlide()
  {
    let size = this.state.slides.length
    let nextSlide = (this.state.currentSlide + 1) % size;

    this.setState(() => {
      return {currentSlide : nextSlide }
    });
  }

  prevSlide()
  {
    // Get previous slide by looping back around the queue of elements
    let size = this.state.slides.length
    let nextSlide = (this.state.currentSlide + (size - 1)) % size;

    this.setState(() => {
      return {currentSlide : nextSlide }
    });
  }

  render() {
    return (
      <div className="slideshow_body">
        <div className="title_content">
          <h2>{this.state.title}</h2>
        </div>

        {/* Slide counter */}
        <b className="slide_counter">
          {this.state.currentSlide + 1} / {this.state.slides.length}
        </b>

        {/* Slide content to display, determined by currentSlide state */}
        <div className="slide_content">
          {this.state.slides[this.state.currentSlide]}
        </div>

        {/* Navigation buttons */}
        <div className="navigation">
            <div>
              <button 
                  onClick={()=> this.nextSlide()} 
                  data-testid={SLIDESHOW_STR.BUTTON_NEXT}>
                  <IconNavRight className="nav_icon"/>
                </button>
            </div>
            <div>
              <button 
                onClick={()=> this.prevSlide()} 
                data-testid={SLIDESHOW_STR.BUTTON_PREV}>
                <IconNavLeft className="nav_icon"/>
              </button>
            </div>
        </div>

      </div>
    );
  }
}

export default Slideshow;