import axios from "../http-common";

class LockService {
  // This function retrieves quest ids, name and locked status
  static async getQuest() {
    try {
      return await axios.get("/questionnaire/lock/");
    } catch(error) {
      throw error;
    }
  }

  static async lockQuest(old_id, new_id) {
    try {
      const data = {'lock' : old_id, 'unlock' : new_id};
      return await axios.post("questionnaire/lock/", data);
    } catch(error) {
      throw error;
    }
  }

  static async lockAll() {
    try {
      return await axios.post("questionnaire/lock/all/");
    } catch(error) {
      throw error;
    }
  }

  // Locking user functions
  static async getUserLock() {
    try {
      return await axios.get("/user/lockcheck/");
    } catch(error) {
      throw error;
    }
  }

  static async lockUsers() {
    try {
      return await axios.post("/user/lock/");
    } catch(error) {
      throw error;
    }
  }

  static async unlockUsers() {
    try {
      return await axios.post("/user/unlock/");
    } catch(error) {
      throw error;
    }
  }
}
export default LockService;