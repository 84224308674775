import axios from "../http-common";

class DiaryService {
	// Currently we will get all quiz data as there should be only one quiz
  static async getAll() {
    try {
      return await axios.get("/diary/?format=json");
    } catch (error) {
      throw error; // Let the caller handle any errors
    }
  }

  static async submit(responses) {
    try {
      return await axios.post("/diary/submit/", responses);
    } catch (error) {
      throw error;
    }
  }
}

export default DiaryService;
