import React, { Component } from "react";
import QuestionnaireService from '../../services/questionnaire.service';
import './questionnaire.css';
import AUTH_STRINGS from '../../strings/auth_strings';

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submitted_data: [],
      loaded: false,
      responseExists: false,
      placeholder: "Loading"
    };

    this.handleMChange = this.handleMChange.bind(this);
    this.handleTChange = this.handleTChange.bind(this);
    this.handleAChange = this.handleAChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    // Setup this questionnaire
    await this.getUnlockedQuestionnaire();
    this.initializeSubmittedData();

    // Check if user has already submitted a response to this questionnaire
    let data = {data: this.state.submitted_data}

    // API request will return FORBIDDEN if a response for this user already
    // exists, and the page will not display the questionnaire.
    try {
      await QuestionnaireService.isSubmittable(data);
      this.setState({responseExists: false});
    }
    catch (error) {
      this.setState({responseExists: true});
    }
  }

  initializeSubmittedData() {
    // We must initialize `this.state.submitted_data`
    // `this.state.data[0]`
    const questionnaire = this.state.data[0];
    
    // If there is no questionnaire available, then we don't do anything
    if (typeof questionnaire !== "undefined") {
      const questions = questionnaire.questions;
      let submitted_data = [];

      questions.forEach(q => {
        // We initialize each index of `this.state.submitted_data` with a
        // default object
        let q_obj = undefined;

        // Multiple Choice Questions
        if (q.question_type === "M") {
          const choices = q.choices;

          // For every choice, create a choice object that saves it's state
          let submitted_choices = [];

          choices.forEach(c => {
            submitted_choices.push({id: c.id, text: c.choice, checked: false}); 
          });

          // Now we create the question object
          q_obj = {
            type:    'M',
            q_id:    q.id,
            q_text:  q.question_text,
            choices: submitted_choices,
          };

          // Store this question object
          submitted_data.push(q_obj);

        } else if (q.question_type === "T") {
          q_obj = {
            type:   'T',
            q_id:   q.id,
            q_text: q.question_text,
            text:   "",
          };

          submitted_data.push(q_obj);
        } else if (q.question_type === "A") {
          q_obj = {
            type:   'A',
            q_id:   q.id,
            q_text: q.question_text,
            resp:   "",
          };

          submitted_data.push(q_obj);
        }
      });

      this.setState({ submitted_data: submitted_data });
    }
  }

  componentWillUnmount() {
    this.loaded = false;
  }

  // Get an unlocked questionnaire
  async getUnlockedQuestionnaire() {
    try {
      const response = await QuestionnaireService.getAll();

      // The lock will remain undefined if an unlocked questionnaire is found.
      // It gets set to an error message if either of the following conditions
      // are true:
      //     1. There are no unlocked questionnaires
      //     2. There is more than 1 unlocked questionnaire
      let lock = undefined;
      try {
        lock = JSON.parse(response.data)['lock']
      } catch(error) {}
      
      // Process result and see if only one questionnaire is locked 
      if (lock === undefined) {
        this.setState({
            data: response.data,
            loaded: true,
        });
      }
      else {
        this.setState({
          placeholder: lock,
        });
      }
    } catch (error) {
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }
      }

      this.setState({ placeholder: error_msg });
    }
  }

  // Handle changes in multiple choice questions
  handleMChange(q_obj) {
    const q_id    = q_obj.q_id;
    const q_type  = q_obj.type;
    const c_id    = q_obj.c_id;
    const checked = q_obj.checked;

    // Update `this.state.submitted_data` with the question's new state
    this.state.submitted_data.forEach(q => {
      // Ensure that we only modify the question if the id and type match
      if (q_type === q.type && q_id === q.q_id) {
        let choices = q.choices;

        // Update the `checked` field
        choices.forEach(c => {
          if (c.id === c_id) {
            c.checked = checked;
          }
        });

        // Update the `choices` field
        q.choices = choices;
      }
    });
  }

  // Handle changes in text question
  handleTChange(q_obj) {
    const q_id   = q_obj.q_id;
    const q_type = q_obj.type;
    const text   = q_obj.text;

    // Update `this.state.submitted_data` with the question's new state
    this.state.submitted_data.forEach(q => {
      // Ensure we only modify the question if the id and type match
      if (q_type === q.type && q_id === q.q_id) {
        q.text = text;
      }
    });
  }

  // Handle changes for agree / disagree question
  handleAChange(q_obj) {
    const q_id   = q_obj.q_id;
    const q_type = q_obj.type;
    const resp   = q_obj.resp;

    // Update `this.state.submitted_data` with the question's new state
    this.state.submitted_data.forEach(q => {
      // Ensure we only modify the question if the id and type match
      if (q_type === q.type && q_id === q.q_id) {
        q.resp = resp;
      }
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
  
    const data = {data: this.state.submitted_data};

    try {
      await QuestionnaireService.submit(data);
      window.location.href = "/";
    }
    catch(error)
    {
      window.location.href = "/forbidden";
    }
  }

  render() {
    // Proceed if the data has been loaded.
    if(typeof this.state.data              !== 'undefined' &&
      typeof this.state.data[0]           !== 'undefined' &&
      typeof this.state.data[0].questions !== 'undefined')
    {
      // Check if the user has already responded, and only display a message if so
      if(this.state.responseExists) {
        return (
          <div className="questionnaire">
            <p>
              Looks like you've already responded to this questionnaire.
              Thanks for your response!
            </p>
          </div>
        );
      }
      
      // Else, display the questionnaire.
      // Data unpacking
      let questions = this.state.data[0].questions;

      return (
        <div className="questionnaire">
          <h1>Questionnaire</h1>

          <form onSubmit={this.handleSubmit} id="submit_form">
            <ul data-testid="questionnaire_component">
              {/* For each question, map its prompt to a list element */}
              <div className="questionnaire_question">
                {questions.map(q => {
                  let questionId = q.question_type + 'question' + q.id;

                  let imagePath = process.env.REACT_APP_BACKEND_URL + q.question_image;
                  return (
                    <li key={q.id} id={questionId} className="question">
                      <b>Question {q.question_number}: </b>  {q.question_text}
                      <div>
                        {(q.question_image !== null ) && <img className="questionnaire_q_image" src={imagePath}/>}
                      </div>

                      <QuestionContent question={q} onMChange={this.handleMChange}
                        onTChange={this.handleTChange} onAChange={this.handleAChange}/>
                    </li>
                  );
                })}
              </div>

            </ul>

            <input type="submit" value="Submit response" className="submit"/>
          </form>
        </div>
      );
    }
    else
    {
      // No data in yet, load page with placeholder
      return(
        <ul data-testid="questionnaire_component">{this.state.placeholder}</ul>
      );
    }
  }
}

// Given question data, determines type of question to load and constructs it.
class QuestionContent extends Component {
  constructor(props) {
    super(props);

    this.handleMChange = this.handleMChange.bind(this);
    this.handleTChange = this.handleTChange.bind(this);
    this.handleAChange = this.handleAChange.bind(this);

    this.state = {
      textCharCount: 0
    }
  }

  // handleChange handler for multiple choice questions
  handleMChange(q, c, e) {
    let q_obj = {
      type:    "M",
      q_id:    q.id,
      c_id:    c.id,
      checked: e.target.checked,
    };
    this.props.onMChange(q_obj);
  }

  // handleChange handler for text questions
  handleTChange(q, e) {
    let q_obj = {
      type: "T",
      q_id: q.id,
      text: e.target.value,
    };

    // Update character count in state
    this.setState({textCharCount: e.target.value.length});

    this.props.onTChange(q_obj);
  }

  // handleChange handler for agree / disagree question
  handleAChange(q, e) {
    let q_obj = {
      type: "A",
      q_id: q.id,
      resp: e.target.id,
    }

    this.props.onAChange(q_obj);
  }

  // Attached below text responses, displays the remaining characters.
  remainingCharDisplay()
  {
    let remaining = 600 - this.state.textCharCount;

    return(
      <div className="remaining_char_display">
        Remaining characters: {remaining}
      </div>
    )
  }

  render() {
    let q = this.props.question;
    let type = q.question_type;
    let choices = q.choices;

    switch(type) {
      // Multiple choice
      case 'M':
        // For each choice, map its label to a label checkbox pair
        return(
            <ul className="questionnaire_choice_list">
              {choices.map(c => {
                let choiceId = 'question' + q.id + 'choice' + c.id;
                let buttonId = 'button' + choiceId;
                let image = process.env.REACT_APP_BACKEND_URL + c.image;
                return (
                  <li key={c.id} id={choiceId} className="questionnaire_choice">

                    {c.image != null &&
                      <div>
                        <img src={image}/>
                        <div>
                          {c.choice} 
                        </div>
                        <input 
                          id={buttonId} 
                          type="checkbox"
                          onChange={(e) => this.handleMChange(q, c, e)}>
                        </input>
                      </div>
                    }

                    {c.image == null &&
                      <div>
                        <input 
                          id={buttonId} 
                          type="checkbox"
                          onChange={(e) => this.handleMChange(q, c, e)}>
                        </input>
                        &nbsp;
                        {c.choice} 
                      </div>
                    }
                  </li>
                )
              })}
            </ul>  
        );

      // Text response
      case 'T':
        return(
          <div>
            <textarea 
              className="response_text"
              type="text"
              maxLength="600"
              onChange={(e) => this.handleTChange(q, e)}
              required>
            </textarea>
            {this.remainingCharDisplay()}
          </div>
        );

      // Agree/Disagree model
      case 'A':
        const radio_group = "A" + q.id;
        return(
          <ul className="agree_disagree_choices">
            <li> 
              <input id="SA" name={radio_group} type="radio" onChange={(e) => this.handleAChange(q, e)} required></input> 
              &nbsp;
              <b>Strongly Agree</b> 
            </li>
            <li> 
              <input id="A" name={radio_group} type="radio" onChange={(e) => this.handleAChange(q, e)}></input> 
              &nbsp;
              <b>Agree</b> 
            </li>
            <li> 
              <input id="N" name={radio_group} type="radio" onChange={(e) => this.handleAChange(q, e)}></input> 
              &nbsp;
              <b>Neither Agree nor Disagree</b> 
            </li>
            <li> 
              <input id="D" name={radio_group} type="radio" onChange={(e) => this.handleAChange(q, e)}></input> 
              &nbsp;
              <b>Disagree</b> 
            </li>
            <li> 
              <input id="SD" name={radio_group} type="radio" onChange={(e) => this.handleAChange(q, e)}></input> 
              &nbsp;
              <b>Strongly Disagree</b>
            </li>
          </ul>
        );

      default:
        return <h3>Question failed to load!</h3>;
    }
  }
}

export default Questionnaire;
