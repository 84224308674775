import React, { Component } from "react";
import AUTH_STRINGS from '../../strings/auth_strings.js';
import LeaderboardService from '../../services/leaderboard.service';
import './leaderboard.css';


class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // Used to store top 10
      user: [], // Used to store the current user
      loaded: false, // Used to determine whether table loaded
    };
  }

  componentDidMount() {
    this.retrieveUsers();
  }

  // Handles retrieving the users and setting the state
  async retrieveUsers() {
    try {
      const response = await LeaderboardService.getAll();
      if(response !== undefined) {
        this.setState({
          list: response.data['top_ten'],
          user: response.data['requester'],
          loaded: true,
        });
      }
    } catch (error) {
      console.log(error);
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }
        console.log(error_msg);
      }
    }
  }

 
  /* Renders leaderboard */
  render() {
    if (typeof this.state.list !== 'undefined' && 
       typeof this.state.list[0] !== 'undefined')
        {
         return(
          <div className="ld_page_content" data-testid="leaderboard_component">
            <h1>Leaderboard</h1>
            <div className="ld_table_container">
              <table className="ld_table" data-testid="leaderboard" >
                <caption className="table_title">Top 10</caption>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Score</th>
                  </tr>
                </thead>

                <tbody className="ld_table_fields">
                  <GetRowsData list = {this.state.list}></GetRowsData>
                </tbody>

              </table>
            </div>

            <div></div>

            <div className="ld_table_container">
              <table className="ld_table" data-testid="this_user">
                <caption className="table_title">You</caption>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Score</th>
                  </tr>
                </thead>

                <tbody className="ld_table_fields">
                  <tr>
                    <td>
                      {this.state.user.username}
                    </td>
                    <td>
                      {this.state.user.score}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        );
    }
    else {
      return(
      <b>User Retrieval Failed</b>)
    } 
  }         
}

export default Leaderboard;


// Processes an individual row of the table
function RenderRow(props) {
  const euser = props.list;

  const testid = 'testid_' + euser.username;
  
  // Extracts data and fills them in as a table row
  return (
    <tr data-testid={testid}>
    <td>{euser.username}</td>
    <td>{euser.score}</td>
    </tr>
  ); 

}

// Processes all the rows of the table
function GetRowsData(props) {
  const data = props.list;

  // Maps through all the elements of the list, each euser, and does RenderRow
  return data.map((row, i) => {
    return <RenderRow key={i} list={row}/>
  });
}