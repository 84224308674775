export default {
    DEFAULT: "Click a bubble!",
    HEAD: "Neck",
    WAIST: "Back",
    SHOULDER: "Shoulders",
    ARM: "Arms, wrist and fingers",
    THIGH: "Thigh and Hip",
    ANKLE: "Foot and ankle",

    // Test values
    BODY_CHART: "bodyChart",
    BODY_INFO: "bodyInfo",
    INFO_TITLE: "infoTitle",

    CLICK_HEAD: "headBubble",
    CLICK_WAIST: "waistBubble",
    CLICK_SHOULDER: "shoulderBubble",
    CLICK_ARM: "armBubble",
    CLICK_THIGH: "thighBubble",
    CLICK_ANKLE: "ankleBubble",
    IMAGES_HEAD: "imagesHead",
    IMAGES_WAIST: "imagesWaist",
    IMAGES_SHOULDER: "imagesShoulder",
    IMAGES_ARM: "imagesArm",
    IMAGES_THIGH: "imagesThigh",
    IMAGES_ANKLE: "imagesAnkle"
};