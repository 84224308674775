import React, { Component } from "react";
import Slideshow from "../slideshows/slideshow";
import "./video_slides.css";
import STRETCH_GUIDE_SLIDES from "./stretch_slide_data";


class StretchGuide extends Component {
  constructor(props) {
    super(props);

    // Array containing each category's array of slides.
    // The array of content is separated out into another file, since
    // it's quite large.
    let buildSlides = [];

    STRETCH_GUIDE_SLIDES.slideContent.forEach(function(contentArray){
      buildSlides.push(
        // Map each array of slides to the JSX that will display the videos
        contentArray.map((slideObj) => {
          return (
            <div key={slideObj.id}>
    
              <div className="slide_video_wrapper">
                <iframe 
                  title={slideObj.title}
                  className="slide_video"
                  src={slideObj.contentPath}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  scrolling="no"
                />
                <div className="disable_popout"></div>
              </div>
            </div>
          );
      }));
    });

    this.state = {
      slides: buildSlides
    };
  }


  render() {
    return (
      <div>
        <h1>Stretch Guide</h1>
        <a href="/module3/learning/">Back to main learning</a>
        <div></div>
        <div className="slide_body">
          <Slideshow data = {this.state.slides[0]} title = "Neck"/>
          <div></div>
          <Slideshow data = {this.state.slides[1]} title = "Shoulders"/>
          <div></div>
          <Slideshow data = {this.state.slides[2]} title = "Back"/>
          <div></div>
          <Slideshow data = {this.state.slides[3]} title = "Arms, wrist, and fingers"/>
          <div></div>
          <Slideshow data = {this.state.slides[4]} title = "Thigh and hips"/>
          <div></div>
          <Slideshow data = {this.state.slides[5]} title = "Foot and ankle"/>
        </div>

      </div>
    );
  }
}

export default StretchGuide;