import tablets1 from "../../../resources/module_content/module1/tablets1.png";
import tablets2 from "../../../resources/module_content/module1/tablets2.png";
import tablets3 from "../../../resources/module_content/module1/tablets3.png";


export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: tablets1,
        textBoxes: [
          ["Shoulders are relaxed and NOT raised.", 340.0, 35],
          ["Back is fully rested.", 20.0, 46],
        ],
      },
      {
        id: "slide1",
        title: "2",
        imagePath: tablets2,
        textBoxes: [
          ["Feet fully flat on a surface.", 30.0, 77],
        ],
      },
      {
        id: "slide2",
        title: "3",
        imagePath: tablets3,
        textBoxes: [
          ["Shoulders are relaxed and NOT raised.", 340.0, 54],
          ["Back is straight and upright.", 20.0, 67],
        ],
      },
    ],
};