import axios from "../http-common";

class LeaderboardService {
  static async getAll() {
    try {
      return await axios.get("/leaderboard/");
    } catch(error) {
      throw error; // Let the caller handle any errors
    }
  }
};
export default LeaderboardService;