import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './module.css';

import bodyChartImage from "../static/module_home_ibd.png"
import learnImage from "../resources/Home-page/m2-MY_PHYSICAL_ENVIRONMENT.png";
import quizImage from "../static/module_home_quiz.png"
import Speedlines from "../resources/Home-page/Speedlines.png";

export default class Module2 extends Component {
  render() {
      /* adds background speed lines to header image */
      let speedlinesStyle = {
        backgroundImage:
        [ `radial-gradient(rgb(255, 255, 255) 55%, transparent 140%)`,
          `url(${Speedlines})`],
        backgroundSize: "100% 100%"
      };

    return (
      /* TODO: 
       * These will be large images as seen in the HiFi prototypes 
       * Right now they're just boxes with text
       * */
      <div className="learningcomponent_links" data-testid="module_two">
        <div className="module_title" style={speedlinesStyle}>
          <h2> 
          <u>MODULE 2</u>  <br></br>
            MY PHYSICAL ENVIRONMENT
          </h2>

        </div>

        <ul className="menu">
          <div className="links">
            
            <Link to="module2/interactive-body-chart">
              <img src={bodyChartImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="module2/interactive-body-chart">INTERACTIVE BODY CHART</Link> </li>
          </div>
          <div className="links">
            <Link to="module2/learning">
              <img src={learnImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="module2/learning">LEARNING COMPONENT</Link> </li>

          </div>
          <div className="links">
            <Link to="/quiz2">
              <img src={quizImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="/quiz2">QUIZ</Link> </li>

          </div>
        </ul>
      </div>
    );
  }
}
