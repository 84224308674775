import phone1 from "../../../resources/module_content/module1/smartphones1.png";
import phone2 from "../../../resources/module_content/module1/smartphones2.png";
import phone3 from "../../../resources/module_content/module1/smartphones3.png";
import phone4 from "../../../resources/module_content/module1/smartphones4.png";

export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: phone1,
        textBoxes: [
          ["Eyes are looking straight ahead towards the screen.", 180.0, 13],
          ["Bottom is at the back of the seat.", 40.0, 64],
        ],
      },
      {
        id: "slide1",
        title: "1",
        imagePath: phone2,
        textBoxes: [
          ["Wrist in a neutral/straight position.", 330.0, 17],
          ["Elbows bent at 90 degrees with forearms horizontal.", 30.0, 25],
        ],
      },
      {
        id: "slide2",
        title: "1",
        imagePath: phone3,
        textBoxes: [
          ["Thighs are horizontal with a 90 degrees to 110 degrees angle at the hips.", 30.0, 52],
        ],
      },
      {
        id: "slide3",
        title: "1",
        imagePath: phone4,
        textBoxes: [
          ["Back is straight and upright.", 170.0, 51],
        ],
      },
    ],
};