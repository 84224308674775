export default {
  LOGO:         'nav_logo',
  MODULE_ONE:   'nav_moduleOne',
  MODULE_TWO:   'nav_moduleTwo',
  MODULE_THREE: 'nav_moduleThree',
  QUESTIONNAIRE:'nav_questionnaire',
  BLOG:         'nav_blog',
  QUIZ:         'nav_quiz',
  LOGIN:        'nav_login',
  LOGOUT:       'nav_logout',
  ADMIN:        'nav_admin',
  LINKS_ADMIN:  'nav_links_admin',
  LINKS_STUDENT:'nav_links_student',
  LINKS_QUESTIONNAIRE: 'nav_links_questionnaire'
};
