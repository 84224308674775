import React, { Component } from "react";
import Slideshow from "../slideshows/slideshow";
import "../module_3/video_slides.css";
import COMPUTER from "./computer_slide_data";
import TABLET from "./tablet_slide_data"
import PHONE from "./phone_slide_data"
import CONSOLE from "./console_slide_data"
import DiagramController from "../slideshows/diagram"

class PhysicalSlides extends Component {
  constructor(props) {
    super(props);

    // Array containing each category's array of slides.
    // The array of content is separated out into another file, since
    // it's quite large.
    let buildSlides = [];
    const diagramName = this.props.title;

    this.props.slideContent.forEach(function(contentArray){
      buildSlides.push(
        // Map each array of slides to the JSX that will display the videos
        <div key={contentArray.id}>
          <DiagramController
            textBoxes={contentArray.textBoxes}
            imagePath={contentArray.imagePath}
            diagramName={diagramName}
            />
        </div>
      );
    });

    this.state = {
      slides: buildSlides
    };
  }

  render() {
    return (
      <div>
        <h1>Setting up the physical environment during ICT use</h1>
        <a href="/module2/learning">Back to learning home</a>
        <div className="slide_body">
          <Slideshow data = {this.state.slides} title = {this.props.title}/>
        </div>
      </div>
    );
  }
}

export class ComputerPhysicalSlides extends Component {
  render() {
    return (
    <PhysicalSlides
      slideContent={COMPUTER.slideContent}
      title="Computer"/>
      );
  };
}

export class TabletPhysicalSlides extends Component {
  render() {
    return (
    <PhysicalSlides
      slideContent={TABLET.slideContent}
      title="Tablets"/> 
      );
  };
}

export class PhonePhysicalSlides extends Component {
  render() {
    return (
    <PhysicalSlides
      slideContent={PHONE.slideContent}
      title="Smartphones"/> 
      );
  };
}

export class ConsolePhysicalSlides extends Component {
  render() {
    return (
    <PhysicalSlides
      slideContent={CONSOLE.slideContent}
      title="Game Consoles"/> 
      );
  };
}