import React, { Component } from "react";
import { Link } from 'react-router-dom';
import '../learning_navigation.css';
import computersIcon from "../../../resources/module_content/icons/computers_icon.png";
import gameConsolesIcon from "../../../resources/module_content/icons/game_consoles_icon.png";
import phonesIcon from "../../../resources/module_content/icons/smartphones_icon.png";
import tabletIcon from "../../../resources/module_content/icons/tablet_icon.png";

//import stretchIcon from '../../../static/module3/learning/stretch_icon.jpg';

class LearningOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="page_content">
        <h2>Module 1</h2>

        <p className="learning_description">
        Having an appropriate posture when using technology can reduce 
        the risk of pain, strain, or discomfort in different parts of the 
        body. In this section, you will find images of role models 
        demonstrating appropriate postures when using different technologies 
        such as computers, tablets, smartphones, and gaming consoles. 
        </p>
        <p>Click on any of the icons below to find out more!</p>

        <div className="module_icon_container">

          <div>
            <Link to="/module1/learning/computers">
              <button>
                <div><b>Computers</b></div>
                <img 
                  className="module_icon"
                  src={computersIcon} 
                  alt="Computers"
                />
              </button>
            </Link>
          </div>
          <div>

            <Link to="/module1/learning/tablets">
              <button>
                <div><b>Tablets</b></div>
                <img 
                  className="module_icon"
                  src={tabletIcon} 
                  alt="Tablets"
                />
              </button>
            </Link>
            </div>
            <div>

            <Link to="/module1/learning/phones">
              <button>
                <div><b>Smartphones</b></div>
                <img 
                  className="module_icon"
                  src={phonesIcon} 
                  alt="Smartphones"
                />
              </button>
            </Link>
            </div>

            <div>

            <Link to="/module1/learning/consoles">
              <button>
                <div><b>Game Consoles</b></div>
                <img 
                  className="module_icon"
                  src={gameConsolesIcon} 
                  alt="Consoles"
                />
              </button>
            </Link>
          </div>

        </div>
      </div>
    );
  }
}

export default LearningOne;