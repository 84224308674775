import React, { Component } from 'react';
import LockService from '../../services/lock.service';
import AUTH_STRINGS from '../../strings/auth_strings';



export default class LockUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_state: undefined,
      placeholder: "Loading",
    };

    this.getUserLock = this.getUserLock.bind(this);
    this.lockUsers = this.lockUsers.bind(this);
    this.unlockUsers = this.unlockUsers.bind(this);

    
  }

  async componentDidMount() {
    await this.getUserLock();
  }


  /* Used to send a lockUsers request by the lock user button */
  async lockUsers(event) {
    event.preventDefault();
    await LockService.lockUsers();

    /* Refresh data so component refreshes */
    await this.getUserLock();
  }

  /* Used to send a unlockUsers request by the unlock user button */
  async unlockUsers(event) {
    event.preventDefault();
    await LockService.unlockUsers();

    /* Refresh data so component refreshes */
    await this.getUserLock();
  }

  /* Function that queries backend for the list of questionnaires to display */
  async getUserLock() {
    try {
      const response = await LockService.getUserLock();
      this.setState({
          user_state: response.data['unlocked'],
      });
    } catch (error) {
      // This will always get set in the switch statement below
      // I only do it like this to stop the linter from complaining about the
      // switch statement requiring a default case
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.FORBIDDEN;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }

        this.setState({ placeholder: error_msg });
      }
    }
  }

  render() {
    // If `this.state.quest_list` is undefined, then an error must have occurred
    if (this.state.user_state !== undefined)
    {
      let string = "True";
      if (this.state.user_state === false) {
        string = "False";
      }
      return(
        <div data-testid="user_lock_component">

          <div data-testid="list_message">
            <b id="active_message">Students active: {string}</b>
          </div>

            <button id="lock_button" type="button" onClick={this.lockUsers}>
              Lock Student Accounts
            </button>

            <button id="unlock_button" type="button" onClick={this.unlockUsers}>
              Unlock Student Accounts
            </button>
        </div>
      );
    } else {
      return(
        this.state.placeholder
      );
    }
  }
}
