import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-type': 'application/json',
    'accept': 'application/json', 
    // Set the `Authorization` header to use the locally stored access token
    'Authorization': `JWT ${localStorage.getItem('jwt_access')}`,
 
  },
});

// Need another axios instance that isn't being accepted to do the refresh call
// with
const refreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-type': 'application/json',
    'accept': 'application/json', 
    // Set the `Authorization` header to use the locally stored access token
    'Authorization': `JWT ${localStorage.getItem('jwt_access')}`,
  }
});

// Set up an interceptor to refresh the token at every API request
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const request = error.config;

    // If we get an error on the /token/refresh endpoint, we must stop here
    if (error.response        !== undefined &&
        error.response.status >= 400        &&
        request.url           === axiosInstance.baseURL + '/token/refresh/')
    {
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // We got an error
    if (error.response            !== undefined &&
        error.response.status     === 401       &&
        error.response.statusText === "Unauthorized")
    {
      // Use the refresh token to get a new access token
      const refresh_token = localStorage.getItem('jwt_refresh');

      if (refresh_token) {
        // We must check the expiry date of the refresh token
        const now = Math.ceil(Date.now() / 1000);
        const expires = JSON.parse(atob(refresh_token.split('.')[1])).exp;

        // The token hasn't expired
        if (expires > now) {
          return refreshInstance.post('/token/refresh/', {"refresh": refresh_token}).then(
            response => {
              const jwt_tokens = response.data;

              // Store the new tokens into localStorage
              localStorage.setItem('jwt_access', jwt_tokens.access);
              localStorage.setItem('jwt_refresh', jwt_tokens.refresh);
              
              // Set the header here to prevent the requirement of a page refresh
              axiosInstance.defaults.headers['Authorization'] = 'JWT ' + jwt_tokens.access;
              refreshInstance.defaults.headers['Authorization'] = 'JWT ' + jwt_tokens.access;
              request.headers['Authorization'] = 'JWT ' + jwt_tokens.access;

              return refreshInstance(request);
            }
          ).catch(error => {   
            // If user is set to inactive, it will have this string in it. If 
            // that is the case, we wanna kick them out of the website and 
            // delete their tokens
            if (error.request.response.includes("inactive")) {
              localStorage.removeItem('jwt_access');
              localStorage.removeItem('jwt_refresh');
              window.location.href = '/login';
            }
          });
        } else {
          // The token has expired. The user needs to login again
          console.log("Refresh token expired", expires, now);
          window.location.href = '/login';
        }
      }
    }

    // If we get here, it means the user is authorized but does not have the
    // perms required to do whatever they're trying to do
    return Promise.reject(error);
  }
);

export default axiosInstance;
