import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './app.css';

import MODULE_STRINGS from './strings/module_strings';
import AUTH_STRINGS from './strings/auth_strings';

import Navbar from './components/navbar';
import Home from './components/home';
import Quiz1 from './components/quiz/quiz_1_controller';
import Quiz2 from './components/quiz/quiz_2_controller';
import Quiz3 from './components/quiz/quiz_3_controller';
import Diary from './components/diary';
import Questionnaire from './components/questionnaire/questionnaire';
import Blog from './components/blog';
import Module1 from './components/module_one';
import Module2 from './components/module_two';
import Module3 from './components/module_three';
import StretchGuide from './components/learning_components/module_3/stretch_guide';
import Login from './components/authentication/login';
import Logout from './components/authentication/logout';
import AuthHelpers from './helpers/auth';
import ErrorPage from './components/authentication/error_page';
import InteractiveBodyChart from './components/interactive_body_chart/interactive_body_chart';
import AdminPanel from './components/admin/admin_panel';
import ProtectedRoute from './components/authentication/protected_route';
import LearningOne from './components/learning_components/module_1_posture/learning_one';
import LearningTwo from './components/learning_components/module_2_physical_env/learning_two';
import { ComputerPostureSlides, TabletPostureSlides, PhonePostureSlides, ConsolePostureSlides} from './components/learning_components/module_1_posture/posture_slides';
import { ComputerPhysicalSlides, TabletPhysicalSlides, PhonePhysicalSlides, ConsolePhysicalSlides} from './components/learning_components/module_2_physical_env/physical_slides';
import LearningThree from './components/learning_components/module_3/learning_three';
import TaskVariation from './components/learning_components/module_3/task_variation';
import Leaderboard from './components/leaderboard/leaderboard';

class App extends Component {
  render() {
    return (
      <Router>
        {/*Navigation bar, goes on top of every page only if user is logged in*/}
        {AuthHelpers.isLoggedIn() && <Navbar />}

        <div className="bg-class"></div>

        {/*Page content is loaded here, inline style places it below the navbar*/}
        {/* ProtectedRoute needs a unique key value so it recreates component */}
        <div style={{marginTop: '64px'}}> 
          <Switch>
            <Route exact path='/' component = {Home} />

            {/* Website page paths */}
            <ProtectedRoute exact path="/quiz1" component={Quiz1} key="quiz1" />
            <ProtectedRoute exact path="/quiz2" component={Quiz2} key="quiz2" />
            <ProtectedRoute exact path="/quiz3" component={Quiz3} key="quiz3" />
            <ProtectedRoute exact path="/diary" component={Diary} key="diary"/>
            <ProtectedRoute exact path="/questionnaire" component={Questionnaire} key="questionnaire" />
            <ProtectedRoute exact path="/blog" component={Blog} key="blog"/>
            <ProtectedRoute exact path="/module1" component={Module1} key="m1"/>
            <ProtectedRoute exact path="/module1/learning" component={LearningOne} key="m1learn"/>
            <ProtectedRoute 
              exact 
              path="/module1/learning/computers" 
              component={ComputerPostureSlides} 
              key="m1comp"/>
            <ProtectedRoute 
              exact 
              path="/module1/learning/tablets" 
              component={TabletPostureSlides} 
              key="m1tablet"/>
            <ProtectedRoute 
              exact 
              path="/module1/learning/phones" 
              component={PhonePostureSlides} 
              key="m1phone"/>
            <ProtectedRoute 
              exact 
              path="/module1/learning/consoles" 
              component={ConsolePostureSlides} 
              key="m1console"/>
            <ProtectedRoute exact path="/module2" component={Module2} key="m2"/>
            <ProtectedRoute exact path="/module2/learning" component={LearningTwo} key="m1learn"/>

            <ProtectedRoute 
              exact 
              path="/module2/learning/computers" 
              component={ComputerPhysicalSlides} 
              key="m2comp"/>
            <ProtectedRoute 
              exact 
              path="/module2/learning/tablets" 
              component={TabletPhysicalSlides} 
              key="m2tablet"/>
            <ProtectedRoute 
              exact 
              path="/module2/learning/phones" 
              component={PhonePhysicalSlides} 
              key="m2phone"/>
            <ProtectedRoute 
              exact 
              path="/module2/learning/consoles" 
              component={ConsolePhysicalSlides} 
              key="m2console"/>
            <ProtectedRoute exact path="/module3" component={Module3} key="m3"/>
            <ProtectedRoute exact path="/module3/learning" component={LearningThree} key="m3learn"/>
            <ProtectedRoute 
              exact 
              path="/module3/learning/stretch-guide" 
              component={StretchGuide} 
              key="m3stretch"/>
            <ProtectedRoute 
              exact 
              path="/module3/learning/task-variation" 
              component={TaskVariation} 
              key="m3task"/>

            {/* Administrative paths */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route 
              exact 
              path="/unauthorized" 
              render={() => (
                <ErrorPage errorType={AUTH_STRINGS.UNAUTHORIZED}/>
              )} 
            />
            <Route 
              exact 
              path="/forbidden" 
              render={() => (
                <ErrorPage errorType={AUTH_STRINGS.FORBIDDEN}/>
              )} 
            />
            <Route 
              exact 
              path="/error" 
              render={() => (
                <ErrorPage errorType={AUTH_STRINGS.UNKNOWN_ERROR}/>
              )} 
            />
            <ProtectedRoute exact path="/admin" component={AdminPanel} key="admin"/>

            {/* Body charts need to use the "render" prop for it to receive props */}
            <ProtectedRoute 
              exact 
              path="/module1/interactive-body-chart" key="chart1"
              render={() => (
                <InteractiveBodyChart module={MODULE_STRINGS.MODULE1}/>
              )}
            />
            <ProtectedRoute 
              exact 
              path="/module2/interactive-body-chart" key="chart2"
              render={() => (
                <InteractiveBodyChart module={MODULE_STRINGS.MODULE2}/>
              )}
            />
            <ProtectedRoute 
              exact 
              path="/module3/interactive-body-chart" key="chart3"
              render={() => (
                <InteractiveBodyChart module={MODULE_STRINGS.MODULE3}/>
              )}
            />
            <ProtectedRoute
              exact path="/leaderboard" key="leaderboard"
              component={Leaderboard}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
