import axios from "../http-common";

// Service that polls backend to gather number of accesses for the site
class LogService {
    static async getAccessLogs() {
        try {
            return await axios.get("/log/list/");
        } catch (error) {
            throw error;
        }
    }
}
export default LogService;