import React, { Component } from "react";
import LCS from "../../../strings/learning_component_strings";
import "../learning_components.css";

const DEBUG = false;

const gridOffset = {x: 250, y: 100};
const dimensions = {x: 350, y: 470};
const gridSize = {x: 10, y: 10};
const gridCentreElementOffset = {x: 257, y: 125};
const elementSize = {x: dimensions.x / gridSize.x, y: dimensions.y / gridSize.y};

function convertAngleToPos(angle) {
  // Center position of the circle/eclipse
  const center = {x: 305.0, y: 300.0};
  // offset of each line so it lines up with each text box
  const offset = {x: 50.0, y: 0.0};
  // radius of eclipse that surrounds images
  const radius = {x: 325.0,y: 300};

  const toRad = (angle) => {
    return angle*Math.PI/180.0;
  }

  const getX = (()=> {
    return ((Math.cos(toRad(angle)))*radius.x) + center.x + offset.x;
  });

  const getY = (()=> {
    return ((Math.sin(toRad(angle)))*radius.y) + center.y + offset.y;
  });

  return {x: getX(), y: getY()};
}

export class DiagramController extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getGridPosition(gridIndex) {
    const columnIndex = gridIndex % gridSize.y;
    const rowIndex = Math.round((gridIndex / gridSize.x)-0.5);

    const xposition = gridCentreElementOffset.x + columnIndex * elementSize.x;
    const yposition = gridCentreElementOffset.y + rowIndex * elementSize.y;

    return {
      x: xposition,
      y: yposition
    };
  }

  renderDebugGrid() {
    let grid = [];
    let gridNumber = 0;

    for (let rowIndex = 0; rowIndex < gridSize.y; rowIndex++) {
      for (let columnIndex = 0; columnIndex < gridSize.x; columnIndex++) {

        // Create grid cell
        grid.push(
          <rect
            x = {gridOffset.x + columnIndex * elementSize.x}
            y = {gridOffset.y + rowIndex * elementSize.y}
            width = {elementSize.x}
            height = {elementSize.y}
            style = {{fill: (
              ((rowIndex + columnIndex) % 2 === 0) ?
                "blue" :
                "red"
              ),
              fillOpacity: 0.2
            }}
            />
        );

        // create element label
        grid.push(<text
          x = { this.getGridPosition(gridNumber).x }
          y = { this.getGridPosition(gridNumber).y  }
          class="small"> { gridNumber } </text>);
        gridNumber++;
      }
    }
    
    return grid;
  }

  // Get all text boxes and their positions
  getTextBoxes() {
    const textBoxes = this.props.textBoxes;

    const textBoxJSX = 
     textBoxes.map(t => {
       // Get position of each text box from angle 
      const position = convertAngleToPos(t[1]);
      let boxkey = 0;
      return <div className = {LCS.TEXT_BOX_CLASS}
        key = {`${this.props.diagramName}box${boxkey++}`}
        style = {{
          top: `${position.y}px`,
          left: `${position.x}px`}}>
        {t[0]}

        </div>
    });

    return textBoxJSX;
  }

  getLines(keyprefix) {
    const offset = {x: 50, y: 10}
    const textBoxes = this.props.textBoxes;
    let linekey = 0;

    const linesJSX = textBoxes.map(l => {
      const position = convertAngleToPos(l[1]);

      const endPos = this.getGridPosition(l[2] ?? 0);

      return (
        <line
          key={`${this.props.diagramName}${keyprefix}${linekey++}`}
          x1 = {position.x+offset.x}
          y1 = {position.y+offset.y}
          x2 = {endPos.x}
          y2 = {endPos.y}>
          </line>    
      );
    });

    return linesJSX;
  }

  render() {
    return (
      <div>
        <div className={LCS.CLASS}>
          <svg className="learning-component-line" width="1000" height="600">
            {this.getLines("line")}
            <g className="learning-component-outline">
              {this.getLines("outline")}
            </g>

            <g className="learning-component-debug">
              {DEBUG ? this.renderDebugGrid() : ""}
            </g>
          </svg>

          {this.getTextBoxes()}

          <img src={this.props.imagePath} className={LCS.IMAGE_CLASS}/>
        </div>
      </div>
    );
  }
}

export default DiagramController;

