import axiosInstance from "../http-common";

class DownloadService {
  // This function retrieves quest ids, name and locked status
  static async download() {
    try {
      return await axiosInstance.get("/export/");
    } catch(error) {
      throw error;
    }
  }

  static async diary_download() {
    try {
      return await axiosInstance.get("/diary/download/");
    } catch(error) {
      throw error;
    }
  }

 
}
export default DownloadService;