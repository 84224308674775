export default {
    slideContent: [
        // Neck
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/17Sfd-U5c-kYyL-zfg43kBTCAj-zRsSQh/preview"
          },
          {
            id: "slide1",
            title: "2",
            contentPath: "https://drive.google.com/file/d/1HJ3z3t3nm4tuAzlFWuBHCCeUH_StzRb-/preview"
          },
          {
            id: "slide2",
            title: "3",
            contentPath: "https://drive.google.com/file/d/18v8FcI8j1ZMOAnxRRzPjmv3cqbCHKgfu/preview"
          },
          {
            id: "slide3",
            title: "4",
            contentPath: "https://drive.google.com/file/d/1p7bnnimEvKAlc6mDsrdENfjvjQdAha1_/preview"
          },
          {
            id: "slide4",
            title: "5",
            contentPath: "https://drive.google.com/file/d/1xWJ4wRWpAdPWVT49bMrBTdpq9jvjuJnw/preview"
          },
        ],
        // Shoulders
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/15ialtTHJDmhZa3KodVO7-hTKbuAYFah0/preview"
          },
          {
            id: "slide1",
            title: "2",
            contentPath: "https://drive.google.com/file/d/17zVl7728Ql6U2xDItbf--IrNSYvUbpp1/preview"
          },
          {
            id: "slide2",
            title: "3",
            contentPath: "https://drive.google.com/file/d/1rZIDiQWvrDKV8nsl4GnsboMbZzQx7Iss/preview"
          },
          {
            id: "slide3",
            title: "4",
            contentPath: "https://drive.google.com/file/d/17AwDeMdwrjsnIOfukocCsqSYDqb99gIt/preview"
          },
          {
            id: "slide4",
            title: "5",
            contentPath: "https://drive.google.com/file/d/13lUQFnmf88T9VghUlwgATUZr2ZiYxW5m/preview"
          },
        ],
        // Back
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/1ZWHJ5V9mSF24CwpOhAd3TEUukQUtF988/preview"
          },
          {
            id: "slide1",
            title: "2",
            contentPath: "https://drive.google.com/file/d/1SdYv96jpQhvgd0TfaDTZ6mmcLzrqtSDK/preview"
          },
          {
            id: "slide2",
            title: "3",
            contentPath: "https://drive.google.com/file/d/1QiYFQVxtT56sV9Ko4ZOy886k19yiDK-8/preview"
          },
          {
            id: "slide3",
            title: "4",
            contentPath: "https://drive.google.com/file/d/14YAQQJ6Hwq2zBUxJ5iu7tywj3MSR7niH/preview"
          },
        ],
        // Arm, wrist, and fingers
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/1QSdNBnFG5ePzSxqf5xV9EUfVpZA1VliE/preview"
          },
          {
            id: "slide1",
            title: "2",
            contentPath: "https://drive.google.com/file/d/1mgh1EP132n-I_gUl6fFMKy4NMGLKHdMA/preview"
          },
          {
            id: "slide2",
            title: "3",
            contentPath: "https://drive.google.com/file/d/1oJo8mt0Th6FXSNNYvDFY-pXr1jxYpKzD/preview"
          },
          {
            id: "slide3",
            title: "4",
            contentPath: "https://drive.google.com/file/d/1KmnjlaF-uH_9U4Li1AeMWT4Tkb-JBcpS/preview"
          },
          {
            id: "slide4",
            title: "5",
            contentPath: "https://drive.google.com/file/d/13PZPwmpS_zPycJejEUhhyUK1_OYkUGMw/preview"
          },
        ],
        // Thigh and hips
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/1dFyrqaEpcR0HusaKHhhu8poVViUGE542/preview"
          },
          {
            id: "slide1",
            title: "2",
            contentPath: "https://drive.google.com/file/d/1ZMQgrtUQIENibW1P0yCGipTOnrTfftBq/preview"
          },
        ],
        // Foot and ankle
        [
          {
            id: "slide0",
            title: "1",
            contentPath: "https://drive.google.com/file/d/1PaN-M9LRBstUszYv-glg6Dg7QH2pvqtD/preview"
          }
        ]
    ]
};