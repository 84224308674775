import React, { Component } from "react";
import { Link }from 'react-router-dom';

import './navbar.css';
import NAVBAR_STRINGS from '../strings/navbar_strings';
import AuthHelper from '../helpers/auth';
import AUTH_STR from "../strings/auth_strings";
import Logo from '../static/Logo12.png';

//Navigation bar that renders on every page.
//Contains links that direct the user to other pages.
class Navbar extends Component {
  constructor(props) {
    super(props);

    // We need to do this in an if statement like this because you can't call
    // this.setState on a component that hasn't been mounted yet, and we want
    // to mount the component with this state already accounted for
    if (AuthHelper.isLoggedIn()) {
      this.state = {
        alreadyLoggedIn: true,
      };
    } else {
      this.state = {
        alreadyLoggedIn: false,
      };
    }
  }

  // Decides what navbar buttons to render, based on user type.
  navbarType() {
    let display;

    switch(AuthHelper.getUserType()) {
      // Questionnaire, no links to display as home page auto-redirects to
      // the questionnaire page
      case AUTH_STR.TYPE_QUESTIONNAIRE:
        display = null;
      break;

      // Admin, has all links as well as the admin panel
      case AUTH_STR.TYPE_ADMIN: 
        display = (
          <ul data-testid={NAVBAR_STRINGS.LINKS_ADMIN}>
            <li> <Link to="/module1" data-testid={NAVBAR_STRINGS.MODULE_ONE}>Module 1</Link>               </li>
            <li> <Link to="/module2" data-testid={NAVBAR_STRINGS.MODULE_TWO}>Module 2</Link>               </li>
            <li> <Link to="/module3" data-testid={NAVBAR_STRINGS.MODULE_THREE}>Module 3</Link>             </li>

            <li> <Link to="/questionnaire" data-testid={NAVBAR_STRINGS.QUESTIONNAIRE}>Questionnaire</Link> </li>
            <li> <Link to="/diary">Diary</Link>                                                            </li>
            <li> <Link to="/blog" data-testid={NAVBAR_STRINGS.BLOG}>Blog</Link>                            </li>
            <li> <Link to="/leaderboard">Leaderboard</Link>                                                </li>
            <li> <Link to="/admin" data-testid={NAVBAR_STRINGS.ADMIN}>Admin</Link></li>
          </ul>
        );
      break;

      // Student and Teacher by default, both have the same links available.
      default:
        display = (
          <ul data-testid={NAVBAR_STRINGS.LINKS_STUDENT}>
            <li> <Link to="/module1" data-testid={NAVBAR_STRINGS.MODULE_ONE}>Module 1</Link>               </li>
            <li> <Link to="/module2" data-testid={NAVBAR_STRINGS.MODULE_TWO}>Module 2</Link>               </li>
            <li> <Link to="/module3" data-testid={NAVBAR_STRINGS.MODULE_THREE}>Module 3</Link>             </li>
            <li> <Link to="/diary">Diary</Link>                                                            </li>
            <li> <Link to="/blog" data-testid={NAVBAR_STRINGS.BLOG}>Blog</Link>                            </li>
            <li> <Link to="/leaderboard">Leaderboard</Link>                                                           </li>
          </ul>
        );
    }

    return display;
  }

  render() {
    return (
      <header className="navbar">
        <nav className="navbar__block">

          {/* TODO: Logo button, will replace with an image later*/}
          <Link to="/" data-testid={NAVBAR_STRINGS.LOGO}>
            <div className="navbar__logo">
              <img alt="" src={Logo}></img>
            </div>
          </Link>
          
          {/* Links inside of the navbar */}
          <div className="navbar__items">
            {this.navbarType()}
          </div>

          {/*Empty element that pushes the log out button to the far right*/} 
          <div className="spacer"></div>

          {/*TODO Create an actual exit/logout button style.*/}
          <div className="navbar__items">
            <ul>
              <li> <Link to="/logout" data-testid={NAVBAR_STRINGS.LOGOUT}>Logout</Link> </li>
            </ul>
          </div>

        </nav>
      </header>
    );
  }
}

export default Navbar;
