import tablets1 from "../../../resources/module_content/module2/tablets1.png";
import tablets2 from "../../../resources/module_content/module2/tablets2.png";
import tablets3 from "../../../resources/module_content/module2/tablets3.png";


export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: tablets1,
        textBoxes: [
          ["Use a pillow as a backrest if the chair is too big.", 340.0, 47],
          ["Use books as a footrest if the floor surface is too low.", 90.0, 72],
          ["Raise the height of the tablet by placing it on top of a book.", 180.0, 41],
        ],
      },
      {
        id: "slide1",
        title: "2",
        imagePath: tablets2,
        textBoxes: [
          ["Raise the height of the tablet by placing it on top of a pillow.", 0.0, 45],
          ["Use books as a footrest if the floor surface is too low.", 30.0, 87],
        ],
      },
      {
        id: "slide2",
        title: "3",
        imagePath: tablets3,
        textBoxes: [
          ["Top of screen must be at or below eye level.", 340.0, 58],
          ["Raise the height of the tablet by placing it on top of a book.", 20.0, 77],
        ],
      },
    ],
};