import comp1 from "../../../resources/module_content/module1/computers1.png";
import comp2 from "../../../resources/module_content/module1/computers2.png";
import comp3 from "../../../resources/module_content/module1/computers3.png";

// Computer
export default {
  slideContent: [
    {
      id: "slide0",
      title: "1",
      imagePath: comp1,
      textBoxes: [
        ["Eyes are looking straight ahead towards the screen.", 230.0, 26],
        ["Head is upright and over the shoulders.", 270.0, 27],
        ["Neck is straight without bending forward or backwards.", 310.0, 38],
        ["Shoulders are relaxed and NOT raised.", 330.0, 47],
        ["Back is fully rested up against the chair backrest.", 0, 57],
        ["Bottom is at the back of the seat.", 20, 66],
  
        ["Thighs are horizontal with a 90 to 110 degree angle at the hips.", 60, 64],
        ["Feet fully flat on the floor.", 100, 72],
        ["Elbows bent at 90 degrees with forearms horizontal.", 150, 55],
        ["Wrist in a neutral/straight position.", 180, 53],
      ],
    },
    {
      id: "slide1",
      title: "2",
      imagePath: comp2,
      textBoxes: [
        ["Back is fully rested up against the chair backrest.", 230.0, 40],
        ["Wrist in a neutral/straight position.", 0.0, 45],
        ["Feet fully flat on the floor.", 45.0, 97],
      ],
    },
    {
      id: "slide2",
      title: "3",
      imagePath: comp3,
      textBoxes: [
        ["Forearms are horizontal.", 30.0, 66],
        ["Wrist in a neutral/straight position.", 100.0, 63],
      ],
    },
  ]
};