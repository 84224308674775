import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './module.css';
import bodyChartImage from "../static/module_home_ibd.png"
import learnImage from "../resources/Home-page/m1-POSTURE.png";
import quizImage from "../static/module_home_quiz.png"
import Speedlines from "../resources/Home-page/Speedlines.png";

export default class Module1 extends Component {
  render() {
    /* adds background speed lines to header image */
    let speedlinesStyle = {
      backgroundImage:
      [ `radial-gradient(rgb(255, 255, 255) 55%, transparent 140%)`,
        `url(${Speedlines})`],
      backgroundSize: "100% 100%"
    };

    return (
      /* TODO: 
       * These will be large images as seen in the HiFi prototypes 
       * Right now they're just boxes with text
       * */
      <div className="learningcomponent_links" data-testid="module_one">
        <div className="module_title" style={speedlinesStyle}>
          <h2> 
            <u>MODULE 1</u> <br></br>
            CHANGE MY POSTURE, RELIEVE MY PAIN
          </h2>

        </div>

        <ul className="menu">
          <div className="links">
            
            <Link to="module1/interactive-body-chart">
              <img src={bodyChartImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="module1/interactive-body-chart">INTERACTIVE BODY CHART</Link> </li>
          </div>
          <div className="links">
            <Link to="module1/learning">
              <img src={learnImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="module1/learning">LEARNING COMPONENT</Link> </li>

          </div>
          <div className="links">
            <Link to="/quiz1">
              <img src={quizImage} alt=""></img>
            </Link>

            <li style={speedlinesStyle}> <Link to="/quiz1">QUIZ</Link> </li>

          </div>
        </ul>
      </div>
    );
  }
}
