import React, { Component } from "react";
import UserService from '../../services/user.service.js';
import './user_create.css';


/* This component handles the fields and submit button on the admin panel that
   has which allows the user to add another experiment user to the database */ 
class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Need to initialise the int fields in the database to null instead of 
      // empty string to avoid database issues
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      age: "",
      gender: "",
      school: "",
      year: "",
      postalCode: "",
      userType: "S",
      userScore: 0,
      control: false,
      failed: false, // Used to determine whether login failed
    };

    this.handleChange      = this.handleChange.bind(this);
    this.handleSubmit      = this.handleSubmit.bind(this);
    this.toggleChange      = this.toggleChange.bind(this);
  }


  // This updates the toggle button state
  toggleChange(event) {
    this.setState({[event.target.name]: ! this.state.control});
  }

  // Changes the state variables any time a new value is entered
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  // Handles user creation
  async handleSubmit(event) {
    // Prevent the default event handler which refreshes the page on submit
    event.preventDefault();

    try {
      await UserService.createUser({
        username: this.state.username,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        age: this.state.age,
        gender: this.state.gender,
        school: this.state.school,
        year: this.state.year,
        postalCode: this.state.postalCode,
        userType: this.state.userType,
        userScore: this.state.userScore,
        control: this.state.control
      });

    } catch (error) {
      // User was not created correctly
      this.setState({failed: true});
    }

    // Needs to reload, because default submit makes post request not work
    window.location.reload();

  }

  // Renders all the html input fields and submit button for creating user
  // Value of fields is set to the corresponding state variable
  // onChange is set so that it updates state in real time
  render() {
    return(
      <div data-testid="user_create_component">

        <b>Create User Form</b>
        <form id="user_form" data-testid="user_form" onSubmit={this.handleSubmit} className="create_user_container">
          <label data-testid="username_field">
            Username: <br></br>
            <input
              name="username"
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
              required
            />
          </label>

          <label data-testid="password_field">
            Password: <br></br>
            <input
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              required
            />
          </label>

          <label data-testid="first_name_field">
            First Name: <br></br>
            <input
              name="firstName"
              type="text"
              value={this.state.firstName}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="last_name_field">
            Last Name: <br></br>
            <input
              name="lastName"
              type="text"
              value={this.state.lastName}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="age_field">
            Age: <br></br>
            <input
              name="age"
              type="number"
              value={this.state.age}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="gender_field">
            Gender: <br></br>
            <input
              name="gender"
              type="text"
              value={this.state.gender}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="school_field">
            School: <br></br>
            <input
              name="school"
              type="text"
              value={this.state.school}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="year_field">
            Year: <br></br>
            <input
              name="year"
              type="number"
              value={this.state.year}
              onChange={this.handleChange}

            />
          </label>

          <label data-testid="postal_code_field">
            Post Code: <br></br>
            <input
              name="postalCode"
              type="number"
              value={this.state.postalCode}
              onChange={this.handleChange}

            />
          </label>

          {/* Drop down menu for user type to implictly verify user type */}
          <label id="drop_down" data-testid="user_type_field">
            User Type: <br></br>
            <select name="userType" id="drop_down" 
            defaultValue = {this.state.userType} onChange={this.handleChange}>
              <option value="T" >Teacher</option>
              <option value="S" >Student</option>
              <option value="A" >Admin</option>
              <option value="Q" >Questionnaire</option>
            </select>

          </label>

          <label data-testid="user_score_field">
            User Score: <br></br>
            <input
              name="userScore"
              type="number"
              value={this.state.userScore}
              onChange={this.handleChange}

            />
          </label>

          { /* Needs to have different onChange to handle toggle status */}
          <label data-testid="control_field">
            Control: 
            <input
              name="control"
              type="checkbox"
              value={this.state.control}
              onChange={this.toggleChange}

            />
          </label>

  
          
          <input type="submit" value="Submit" data-testid="submit"/>
        </form>
      </div>
    );
  }
}

export default UserCreate;

