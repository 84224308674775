import phone1 from "../../../resources/module_content/module2/smartphones1.png";
import phone2 from "../../../resources/module_content/module2/smartphones2.png";
import phone3 from "../../../resources/module_content/module2/smartphones3.png";

export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: phone1,
        textBoxes: [
          ["Backrest tilt must be adjustable.", 0.0, 38],
          ["Seat tilt must be adjustable.", 30.0, 77],
        ],
      },
      {
        id: "slide1",
        title: "1",
        imagePath: phone2,
        textBoxes: [
          ["Use a pillow as a backrest if the couch is too big.", 180.0, 51],
          ["Use books as a footrest if the floor surface is too low.", 30.0, 77],
        ],
      },
      {
        id: "slide2",
        title: "1",
        imagePath: phone3,
        textBoxes: [
          ["Place forearms on top of books to raise the height of the smartphone.", 15.0, 64],
        ],
      },
    ],
};