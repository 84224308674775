import React, { Component } from "react";
import Slideshow from "../slideshows/slideshow";
import "./video_slides.css";
import "./task_variation.css";
import TASK_SLIDES from "./task_slide_data";
import MODULE_STR from "../../../strings/module_strings";
import consoleIcon from "../../../static/module3/learning/console_icon.jpg";
import compIcon from "../../../static/module3/learning/laptop_icon.jpg";
import phoneIcon from "../../../static/module3/learning/phone_icon.jpg";
import tabletIcon from "../../../static/module3/learning/tablet_icon.jpg";

class TaskVariation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: [],
      section: ""
    };
  }

  componentDidMount() {
    // Array containing each category's array of slides.
    // The array of content is separated out into another file, since
    // it's quite large. Built here to set into the state afterwards,
    // so it's only ever built once.
    let buildSlides = [];

    // Fill slides with content
    TASK_SLIDES.slideContent.forEach(function(contentArray){
      buildSlides.push(
        // Map each array of slides to the JSX that will display the videos
        contentArray.map((slideObj) => {
          return (
            <div key={slideObj.id}>
              <h3 className="slide_subtitle">{slideObj.title}</h3>

              <div className="slide_video_wrapper">
                <iframe 
                  title={slideObj.title}
                  className="slide_video"
                  src={slideObj.contentPath}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  scrolling="no"
                />
                <div className="disable_popout"></div>
              </div>
            </div>
          );
      }));
    });

    this.setState(()=> {
      return {slides: buildSlides};
    });
  }

  renderCategory(choice, slides) {
    let displayed;

    // Decide what to render
    switch(choice) {
      case MODULE_STR.TASK_COMPUTER:
        displayed = (
          <div>
            <button
              className="back_button" 
              href="module3/learning/task-variation"
              onClick={() => this.handleClick("")}
              data-testid={MODULE_STR.BUTTON_BACK}
            >
              Back
            </button>
            <div className="slide_body" data-testid={MODULE_STR.TASK_COMPUTER}>
              <Slideshow data = {this.state.slides[0]} title = "Computers"/>
            </div>
          </div>
        );
      break;

      case MODULE_STR.TASK_TABLET:
        displayed = (
          <div className="slide_body" data-testid={MODULE_STR.TASK_TABLET}>
            <button 
              onClick={() => this.handleClick("")}
              data-testid={MODULE_STR.BUTTON_BACK}
            >
              Back
            </button>
            <Slideshow data = {this.state.slides[1]} title = "Tablets"/>
          </div>
        );
      break;

      case MODULE_STR.TASK_PHONE:
        displayed = (
          <div className="slide_body" data-testid={MODULE_STR.TASK_PHONE}>
            <button 
              onClick={() => this.handleClick("")}
              data-testid={MODULE_STR.BUTTON_BACK}
            >
              Back
            </button>
            <Slideshow data = {this.state.slides[2]} title = "Smartphones"/>
          </div>
        );
      break;

      case MODULE_STR.TASK_CONSOLE:
        displayed = (
          <div className="slide_body" data-testid={MODULE_STR.TASK_CONSOLE}>
            <button 
              onClick={() => this.handleClick("")}
              data-testid={MODULE_STR.BUTTON_BACK}
            >
              Back
            </button>
            <Slideshow data = {this.state.slides[3]} title = "Consoles"/>
          </div>
        );
      break;

      default:
        displayed = (
          <div data-testid={MODULE_STR.TASK_DEFAULT}>
            <a href="/module3/learning/" className="back_button" >Back to main learning</a>
            <p>
              Click on any of the technology icons below to learn how to alternate 
              between different tasks when using different forms of technology.
            </p>

            <div className="task_grid_container">
              <div className="task_cell_container">
                <button 
                  data-testid={MODULE_STR.BUTTON_COMPUTER}
                  onClick={() => this.handleClick(MODULE_STR.TASK_COMPUTER)}
                >
                  <div><b>Computers</b></div>
                  <img 
                    className="task_cell_image"
                    src={compIcon} 
                    alt="Computers"
                  />
                </button>
              </div>

              <div className="task_cell_container">
                <button 
                  data-testid={MODULE_STR.BUTTON_TABLET}
                  onClick={() => this.handleClick(MODULE_STR.TASK_TABLET)}
                >
                  <div><b>Tablets</b></div>
                  <img 
                    className="task_cell_image"
                    src={tabletIcon} 
                    alt = "Tablets"
                  />
                </button>
              </div>
              
              <div className="task_cell_container">
                <button 
                  data-testid={MODULE_STR.BUTTON_PHONE}
                  onClick={() => this.handleClick(MODULE_STR.TASK_PHONE)}
                >
                  <div><b>Smartphones</b></div>
                  <img 
                    className="task_cell_image"
                    src={phoneIcon} 
                    alt = "Smartphones"
                  />
                </button>
              </div>

              <div className="task_cell_container">
                <button 
                  data-testid={MODULE_STR.BUTTON_CONSOLE}
                  onClick={() => this.handleClick(MODULE_STR.TASK_CONSOLE)}
                >
                  <div><b>Gaming consoles</b></div>
                  <img 
                    className="task_cell_image"
                    src={consoleIcon} 
                    alt="Gaming consoles"
                  />
                </button>        
              </div>
            </div>
          </div>
        );
      break;
    }

    return displayed;
  }

  handleClick(choice) {
    this.setState(
      {section: choice}
    );

    return true;
  }

  render() {
    return (
      <div className="page_content">
        <h1>Task Variation</h1>

        {this.renderCategory(this.state.section)}
      </div>
    );
  }
}

export default TaskVariation;