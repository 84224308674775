import AUTH_STR from '../strings/auth_strings';

export default class AuthHelpers {
  // Returns true if the user is logged in, false otherwise
  //
  // There's two cases to check for:
  //
  // 1. There is no refresh token in localStorage. This implies the user used
  // the logout API to logout
  // 2. The user hasn't used the website for the past 14 days, which caused
  // the refresh token to expire. We must then check the refresh token
  // to see whether it is indeed expired. If it is not, then simply redirect
  // the user to the home page since they are logged in
  static isLoggedIn() {
    let refresh_token = localStorage.getItem('jwt_refresh');

    if (refresh_token) {
      // Check for expiry
      const now = Math.ceil(Date.now() / 1000);

      // The refresh token is in the format <base64>.<base64>
      // This takes the second base64 value, decodes it, then takes the `exp`
      // field from it which stores the time of expiry
      const expires = JSON.parse(atob(refresh_token.split('.')[1])).exp;

      // The token hasn't expired, the user is logged in
      if (expires > now) {
        return true;
      }
    }
  }

  // Return the current user's user ID
  // This should ONLY be called from parts of the code where the user is
  // guaranteed to be authenticated
  // This is not a secure way to get the user ID, as a user can just modify
  // their token in some cases. It should only be used as a convenience. A
  // request to the backend should not be made with the user ID stored in the
  // JWT token
  static getUserID() {
    const access_token = localStorage.getItem('jwt_access');
    const user_id      = JSON.parse(atob(access_token.split('.')[1])).euser_id;

    return user_id;
  }

  // Return the current user's user type
  // This should ONLY be called from parts of the code where the user is
  // guaranteed to be authenticated
  // The same security implication as in the `getUserID` function applies here
  static getUserType() {
    const access_token = localStorage.getItem('jwt_access');
    const user_type    = JSON.parse(atob(access_token.split('.')[1]))
                          .user_type;
    let return_type;

    // Match returned user type to a string representation before returning
    switch(user_type) {
      case 'T':
        return_type = AUTH_STR.TYPE_TEACHER;
      break;
      case 'S':
        return_type = AUTH_STR.TYPE_STUDENT;
      break;
      case 'Q':
        return_type = AUTH_STR.TYPE_QUESTIONNAIRE;
      break;
      case 'A':
        return_type = AUTH_STR.TYPE_ADMIN;
      break;
      default:
        return_type = null;
      break;
    }

    return return_type;
  }
}
