import comp1 from "../../../resources/module_content/module2/computers1.png";
import comp2 from "../../../resources/module_content/module2/computers2.png";
import comp3 from "../../../resources/module_content/module2/computers3.png";

// Computer
export default {
  slideContent: [
    {
      id: "slide0",
      title: "1",
      imagePath: comp1,
      textBoxes: [
        ["Increase the font size or zoom in if you cannot read the words on the screen.", 315.0, 31],
        ["Backrest tilt must be adjustable.", 335.0, 39], 
        ["Use a pillow as a backrest if the chair is too big.", 0.0, 57],
        ["Seat tilt must be adjustable.", 30.0, 67],
        ["Leave a gap between the edge of the desk and the keyboard.", 50.0, 44],
        ["Armrest must be adjustable to enable forearms to rest on the desk. ", 90.0, 55],
        ["Use books as a footrest if the floor surface is too low.", 130.0, 73],
        ["Raise the height of the computer by placing it on top of a book.", 180.0, 43],
        ["Top of screen must be at or below eye level.", 230.0, 31],
        
      ],
    },
    {
      id: "slide1",
      title: "2",
      imagePath: comp2,
      textBoxes: [
        ["Top of screen must be at or below eye level.", 300.0, 47],
        ["Raise the height of the computer by placing it on top of a pillow.", 0.0, 36],
        ["Use books as a footrest if the floor surface is too low.", 45.0, 97],
      ],
    },
    {
      id: "slide2",
      title: "3",
      imagePath: comp3,
      textBoxes: [
        ["Raise the height of the computer by placing it on top of a book.", 20.0, 67],
      ],
    },
  ]
};
