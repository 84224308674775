import game1 from "../../../resources/module_content/module1/game_consoles1.png";
import game2 from "../../../resources/module_content/module1/game_consoles2.png";
import game3 from "../../../resources/module_content/module1/game_consoles3.png";

export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: game1,
        textBoxes: [
          ["Neck is straight without bending forward or backwards.", 10.0, 29],
          ["Neck is straight without bending forward or backwards.", 10.0, 57],
        ],
      },
      {
        id: "slide0",
        title: "1",
        imagePath: game2,
        textBoxes: [
          ["Head is upright and over the shoulders.", 310.0, 24],
          ["Head is upright and over the shoulders.", 310.0, 31],
        ],
      },
      {
        id: "slide0",
        title: "1",
        imagePath: game3,
        textBoxes: [
          ["Eyes are looking straight ahead towards the screen.", 230.0, 26],
          ["Eyes are looking straight ahead towards the screen.", 230.0, 22],
        ],
      },
    ],
};