import React, { Component } from 'react';
import BlogService from '../services/blog.service';
import AUTH_STRINGS from '../strings/auth_strings';
import AuthHelper from '../helpers/auth.js';

import './blog.css';

export default class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:             undefined,
      placeholder:      "Loading",
      new_post_title:   "",
      new_post_body:    "",
      component_changed: false,
    };

    this.getPosts     = this.getPosts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.likePost     = this.likePost.bind(this);
    this.deletePost   = this.deletePost.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();

    const title   = this.state.new_post_title;
    const body    = this.state.new_post_body;

    await BlogService.create(title, body);

    window.location.href = "/blog";
  }

  async componentDidMount() {
    await this.getPosts();
  }

  // Any time the `textarea` in this component is modified, we want
  // `this.state.new_post` to be set to that text
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async deletePost(post_id) {
    // Delete the post
    await BlogService.deletePost(post_id);

    // Get the posts again because they possibly just got updated
    this.getPosts();

    // Ghetto way to re-render the component, just set the `component_changed`
    // field to true if false, and false if true
    if (this.state.component_changed === true)
      this.setState({ component_changed: false });
    else
      this.setState({ component_changed: true });
  }

  async likePost(post_id) {
    // Like the post
    await BlogService.like(post_id);

    // Get the posts again because they just got updated. This is required to
    // update the `this.state.data` field with the new post. The component
    // is about to be reloaded, and we want the `Like` button to change into
    // an `Unlike` button, the functionality that handles that depends on the
    // `this.state.data` object being updated
    this.getPosts();

    // Ghetto way to re-render the component, just set the `component_changed`
    // field to true if false, and false if true
    if (this.state.component_changed === true)
      this.setState({ component_changed: false });
    else
      this.setState({ component_changed: true });
  }

  async unlikePost(post_id) {
    // Unlike the post
    await BlogService.unlike(post_id);

    // Get the posts again because they just got updated. This is required to
    // update the `this.state.data` field with the new post. The component
    // is about to be reloaded, and we want the `Unlike` button to change into
    // a `Like` button, the functionality that handles that depends on the
    // `this.state.data` object being updated
    this.getPosts();

    // Ghetto way to re-render the component, just set the `component_changed`
    // field to true if false, and false if true
    if (this.state.component_changed === true)
      this.setState({ component_changed: false });
    else
      this.setState({ component_changed: true });
  }

  async getPosts() {
    try {
      const response = await BlogService.getAll();

      // We have to ensure that if the backend is empty, `this.state.data` must
      // get set to an empty array (not `undefined`)
      if (response.data.length === 0) {
        this.setState({
          data: [],
        });
      } else {
        this.setState({
          data: response.data,
        });
      }

    } catch (error) {
      // This will always get set in the switch statement below
      // I only do it like this to stop the linter from complaining about the
      // switch statement requiring a default case
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.FORBIDDEN;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }

        this.setState({ placeholder: error_msg });
      }
    }
  }

  render() {
    // If `this.state.data` is undefined, then an error must have occurred
    if (this.state.data !== undefined)
    {
      // We make a copy of `this.state.data` and reverse it. The data is
      // already sorted by the timestamp of when it was posted, but we want
      // the most recent posts to appear at the top of the page, which we can
      // do by reversing the array
      const posts = this.state.data.slice(0).reverse();

      return(
        <div data-testid="blog_component" className="blog">

          <div className="new_post_form">
            <form onSubmit={this.handleSubmit} id="submit_form">
              <label>
                New Post: <br/>
                <input name="new_post_title" value={this.state.new_post_title} onChange={this.handleChange} maxLength="99"/>
                <textarea name="new_post_body" cols="100" rows="10" value={this.state.new_post_body} onChange={this.handleChange} maxLength="299"/>
              </label><br/>
              <input type="submit" value="Create Post" data-testid="submit"/>
            </form>
          </div>

          {posts.map(p => {
            // We store the user type of the current user. We use this to 
            // determine whether the user is a teacher or an admin, as both
            // of these users should be able to delete all blog posts
            const user_type = AuthHelper.getUserType();

            // We need to store the user id of the current user visiting the 
            // page as well. We use this to check which posts have been liked 
            // by the current user, and to decide which posts should be
            const user_id = AuthHelper.getUserID();

            // Check if the user id of the current user exists in the list of
            // users that liked this post
            const liked_users = p.liked_users;
            const liked = liked_users.some((like) => {
              return like.user === user_id;
            });

            const num_of_likes = liked_users.length;

            // Decide if the "Delete" button should be shown underneath a post
            //
            // We show the delete button if either of the following two
            // conditions are met:
            // 
            //     1. The currently logged in user is a teacher / admin
            //     2. The author of the post is the same as the currently
            //        logged in user
            let show_delete_button = false;
            if (user_type === 'admin_user' || user_type === 'teacher_user') {
              show_delete_button = true;
            } else if (user_id === p.author) {
              show_delete_button = true;
            }

            return(
              <div data-testid="blog_post" className="post" key={"post " + p.id}>
                <div data-testid="author" className="author">
                  <b>Author: {p.author_name}</b>
                </div>
                <div data-testid="title" className="title">
                  <div>{p.title}</div>
                </div>
                <div data-testid="body" className="body">
                  <div>{p.body}</div>
                </div>
                
                <div className="blog_bottom_display">
                  <div>
                    { !liked && 
                        <button onClick={() => this.likePost(p.id)}>
                          Like
                        </button> 
                    }
                    { liked && 
                        <button onClick={() => this.unlikePost(p.id)}>
                          Unlike
                        </button> 
                    }
                  </div>
                  <div>
                  { show_delete_button &&
                      <button onClick={() => this.deletePost(p.id)}>
                        Delete
                      </button>
                  }
                  </div>

                  <div></div>

                  <div>{num_of_likes} likes </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return(
        <ul data-testid="blog_component">{ this.state.placeholder }</ul>
      );
    }
  }
}
