export default {
    MODULE1: 'module1',
    MODULE2: 'module2',
    MODULE3: 'module3',

    TASK_DEFAULT: 'task_default',
    TASK_COMPUTER: 'task_computer',
    TASK_TABLET: 'task_tablet',
    TASK_PHONE: 'task_phone',
    TASK_CONSOLE: 'task_console',
    BUTTON_BACK: 'button_back',
    BUTTON_COMPUTER: 'button_computer',
    BUTTON_TABLET: 'button_tablet',
    BUTTON_PHONE: 'button_phone',
    BUTTON_CONSOLE: 'button_console',
};