import React, { Component } from "react";
import QuizService from '../../services/quiz.service';
import AUTH_STRINGS from '../../strings/auth_strings';
import QUIZ_STRINGS from "../../strings/quiz_strings";
import QuizQuestionController from "./quiz_question_controller";
import './quiz.css';

class QuizController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      placeholder: "Loading",
      questions: [],
      submitted_data: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeCount  = this.changeCount.bind(this);
  }

  async componentDidMount() {
    await this.getAll();
    this.initializeSubmittedData();
  }

  componentWillUnmount() {
    this.loaded = false;
  }

  // Initializes the submitted data to default values for later usage
  initializeSubmittedData() {
    // IMPORTANT: Use index 2 of this.state.data for Module 3
    const data = this.state.data[2];

    if (typeof data !== "undefined") {
      const questions = data.questions;

      let submitted_data = [];

      // Initialize each choice in each question to false
      questions.forEach(q => {
        let choices = q.choices;
        
        choices.forEach(c => {
          c.checked = false;
        });

        submitted_data.push({ q_id: q.id, choices: q.choices });
      });

      this.setState({ submitted_data: submitted_data });
    }
  }

  // This method is not intended to be used anywhere. It is a placeholder to
  // refer to as a blueprint when writing other methods that make requests to
  // the Django REST API
  // Get all quiz questions, answers, choices, etc
  async getAll() {
    try {
      const response = await QuizService.getAll();
      // Randomize the data
      let data = response.data;
      data[2].questions = getRandom(data[2].questions, Math.min(data[2].questions.length, 8));
      // Update the quiz class's state with the new data
      this.setState({
        data: data,
        loaded: true,
      });
      this.state.data[2].questions = getRandom(this.state.data[2].questions, Math.min(this.state.data[2].questions.length, 8));
    } catch (error) {
      // This will always get set in the switch statement below
      // I only do it like this to stop the linter from complaining about the
      // switch statement requiring a default case
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }
      }

      this.setState({ placeholder: error_msg });
    }
  }


  async handleSubmit(e) {
    e.preventDefault()
   
    const correct = (await QuizService.submit(this.state.submitted_data))
                      .data.correct;

    // Alert box to show the number of correct choices
    alert("Correct choices: " + correct);

    window.location.href = "/";
  }

  // This function modifies `this.state.submitted_data` according to the
  // choices the user has selected
  changeCount(question_id, choice_id, checked) {
    let question_data = this.state.submitted_data;

    question_data.forEach(q => {
      // Ensure the question id matches first
      if (q.q_id === question_id) {
        let choices = q.choices;
  
        // If the choice id matches, we set the state
        choices.forEach(c => {
          if (c.id === choice_id) {
            c.checked = checked;
          }
        });
      }
    });

    this.setState({ submitted_data: question_data });
  }

  render() {
    // Check if the data has come arrived yet
    // IMPORTANT: Use index 2 of this.state.data for Module 3
    const apiData = (this.state.data) ? this.state.data[2] : null;
    
    // Render quiz if data is available
    if(apiData) {

      //Data unpacking
      const quizName = apiData.quiz_name;
      const module = apiData.module;
      const questions = apiData.questions;

      return (
        <div id={QUIZ_STRINGS.QUIZ_ID} className="container">
          <b>{QUIZ_STRINGS.TEST_Q_NAME}</b>{quizName} <br/>
          <b>{QUIZ_STRINGS.TEST_M_NAME}</b>{module} <br/>
          <form onSubmit={this.handleSubmit} id="submit_form">
            <ul>
            { questions.map(q => {
              return (
                <QuizQuestionController
                  id = {`${QUIZ_STRINGS.QUESTION.PLAIN_TEXT}${q.id}`}
                  key = {q.id}
                  question_id  = {q.id}
                  question_image = {q.image}
                  questionText = {q.question_text}
                  className = {QUIZ_STRINGS.QUESTION.CONTAINER_CLASS}
                  choiceTotal = {q.num_choices}
                  choices = {q.choices}
                  changeCount = {this.changeCount}
                  handleSubmit={this.handleSubmit}
                />
              );
            }) } 
            </ul>
            
            <input type="submit" value="Submit" className="submit"/>
          </form>
        </div>
      );
    }
    
    // Otherwise return error
    return (
      <ul data-testid="quiz_component">{this.state.placeholder}</ul>
    );
    
  }
}

export default QuizController;

// This codewas taken from https://stackoverflow.com/a/19270021 on the 
// 20/08/2020, from the user Bergi. 
function getRandom(arr, n) {
  let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
