import React, { Component } from "react";
import UserService from '../../services/user.service.js';
import AUTH_STRINGS from '../../strings/auth_strings.js';
import './user_list.css';


class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loaded: false, // Used to determine whether table loaded
    };
  }

  componentDidMount() {
    this.retrieveUsers();
  }

  // Handles retrieving the users and setting the state
  async retrieveUsers() {
    try {
      const response = await UserService.getUsers();
      if(response !== undefined) {
        this.setState({
          list: response.data,
          loaded: true,
        });
      }
    } catch (error) {
      console.log(error);
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }
        console.log(error_msg);
      }
    }
  }

 
  /* Stores the list that we retrieved in a table. Uses the components GetHeader
     and GetRowsData to display the table headers and rows respectively */
  render() {
    if (typeof this.state.list !== 'undefined' && 
       typeof this.state.list[0] !== 'undefined')
        {
         return(
          <div data-testid="user_list_component" >
            <b>User List</b>

            <div className="users_table_container">
              <table className="users_table" data-testid="user_list" >
                <thead>
                  <tr><GetHeader list = {this.state.list}></GetHeader></tr>
                </thead>

                <tbody>
                  <GetRowsData list = {this.state.list}></GetRowsData>
                </tbody>

              </table>
            </div>
            
          </div>

        );
    }
    else {
      return(
      <b>User Retrieval Failed</b>)
    }
  }         
}

export default UserList;

// Breaks apart the necessary table headers required for the admin panel
function getKeys(props) {
  const euser = props.list[0];
  const user = euser.user;

  // Find first header of the euser, which will be user
  const firstHeader = Object.keys(euser).slice(0, 1);
  
  // Extract all the headers within the user array
  const userHeaders = Object.keys(user);

  // Extract the rest of the headers, filtering out latest diary submission
  const lastHeaders = Object.keys(euser).slice(1, (Object.keys(euser).length - 1));

  const keys = {userHead : firstHeader.concat(userHeaders),
                experimentHead : lastHeaders};

  return keys;
}

// Returns the headers of the table
function GetHeader(props) {
  var keys = getKeys(props);

  var tempArray = [];
  tempArray = (keys.userHead.concat( keys.experimentHead));

  return (tempArray.map((key, index) => {
    return <th key={key}>{key.toUpperCase()}</th>
  }));
}

// Processes an individual row of the table
function RenderRow(props) {
  const euser = props.list;

  const testid = 'testid_' + euser.user.username;
  
  // Extracts data and fills them in as a table row
  return (
    <tr data-testid={testid}><td>{euser.user.id}</td>
    <td>{euser.user.id}</td>
    <td>{euser.user.username}</td>
    <td>{euser.user.first_name}</td>
    <td>{euser.user.last_name}</td>
    <td>{euser.age}</td>
    <td>{euser.gender}</td>
    <td>{euser.school}</td>
    <td>{euser.year}</td>
    <td>{euser.postal_code}</td>
    <td>{euser.user_type}</td>
    <td>{euser.user_score}</td>
    <td>{euser.control}</td></tr> 
  ); 

}

// Processes all the rows of the table
function GetRowsData(props) {
  const data = props.list;

  // Maps through all the elements of the list, each euser, and does RenderRow
  return data.map((row, i) => {
    return <RenderRow key={i} list={row}/>
  });
}