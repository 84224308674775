import React from 'react';
import { Redirect } from 'react-router-dom';
import AdminPanel from '../admin/admin_panel';
import Questionnaire from '../questionnaire/questionnaire';
import UserService from '../../services/user.service';
import Module1 from '../module_one';
import Module2 from '../module_two';
import Module3 from '../module_three';
import Quiz1 from '../quiz/quiz_1_controller';
import Quiz2 from '../quiz/quiz_2_controller';
import Quiz3 from '../quiz/quiz_3_controller';
import Diary from '../diary';
import Blog from '../blog';
import AUTH_STRINGS from '../../strings/auth_strings';

/* This component is used to allow the imported component or render in case of 
   interactive body chart to be rendered and show up, only if the user has the 
   right permissions to access it. If they do not, they instead will be 
   redirected to the homepage */
class ProtectedRoute extends React.Component {
  /* Constructor sets initial state, parses the prop, and calls auth check */
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isAllowed: false,
      diaryRedirect: false,
      response: 0,
    };

    const Component = this.props.component;
    let to_check;
    switch(Component) {
      case AdminPanel:
        to_check = 'AdminPanel';
        break;

      case Questionnaire:
        to_check = 'Questionnaire';
        break;

      case Module1:
        to_check = 'Module1';
        break;

      case Module2:
        to_check = 'Module2';
        break;

      case Module3:
        to_check = 'Module3';
        break;

      case Quiz1:
        to_check = 'Quiz1';
        break;

      case Quiz2:
        to_check = 'Quiz2';
        break;

      case Quiz3:
        to_check = 'Quiz3';
        break;

      case Diary:
        to_check = 'Diary';
        break;

      case Blog:
        to_check = 'Blog';
        break;

      /* Default will be interactive body chart */
      default:
        to_check = 'BodyChart';
    }
    
    this.checkDiaryRedirect();
    this.authCheck(to_check);
  }

  /* This method imports the type of component that they are trying to access
    broken down in to 3 groups, Learning, AdminPanel and Questionnaire. It 
    then uses backend to check if user has right permission and sets the 
    state accordingly */
  async authCheck(component) {
    try {
      // Send off backend response and set state based on outcome
      const response = await UserService.checkUser(component);
      // 'true' here instead of true because that its json string 
      if(response.data === 'true') {
          this.setState({
              isAllowed: true,
              isLoading: false
          });
      }
      else {
        this.setState({
          isAllowed: false,
          isLoading: false
        });
      }
    } catch (error) {
      let error_msg = undefined;

      if (error.response) {
        switch (error.response.status) {
        case 401:
          error_msg = AUTH_STRINGS.UNAUTHORIZED;
          break;
        case 403:
          error_msg = AUTH_STRINGS.FORBIDDEN;
          break;
        default:
          error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
          break;
        }
        this.setState({
          isAllowed: false,
          isLoading: false,
          response: error.response.status
        });
        console.log(error_msg);
      }
    }
  }

    /* This function sets the diaryRedirect value to true if the user has not
       made a diary submission in the last seven days */
    async checkDiaryRedirect() {
      try {
        const response = await UserService.checkDiary();
        if(response.status == 200) {
          if(response.data >= 7) {
            this.setState({
              diaryRedirect: true
            });
          }
          else
          {
            this.setState({
              diaryRedirect: false
            });
          }
        }
        else
        {
          this.setState({
            diaryRedirect: false
          });
        }
      } catch (error) {
        console.log("Error in checkdiary API call: " + error);
        this.setState({
          diaryRedirect: false
        });
      }
    }

  // Decide what error page to redirect the user to, based on the response
  // set in the state.
  responseRedirect () {
    let redirect

    switch(this.state.response)
    {
      case 401:
        redirect = (<Redirect to={{ pathname: '/unauthorized' }} />);
      break;

      case 403:
        redirect = (<Redirect to={{ pathname: '/forbidden' }} />);
      break;

      default:
        redirect = (<Redirect to={{ pathname: '/error' }} />);
      break;
    }

    return redirect;
  }

  /* Render method tries to get a Component and a Render from the props. This
      is because it can be either one. Render is used when you need to pass in 
      props to a component, such as interactive body chart */
  render() {
      const Component = this.props.component;
      const Render = this.props.render;
      
      /* Checks if it has finished loading, and if user is allowed to view */
      if (!this.state.isLoading && this.state.isAllowed) {
          /* Need to check whether its a component or a render as prop */
          if (Component !== undefined) {
              return (<Component />);
          }
          else {
              return (<Render />);
          }
      }
      /* Check if the user needs to be redirected to the diary component */
      else if (this.state.diaryRedirect) {
        return (<Redirect to={{pathname: '/diary'}} />)
      }
      /* If its loading, then we just return null while we wait */
      else if (this.state.isLoading) {
          return (
              <b>Loading</b>
          ); 
      }

      /* If authentication fails, we redirect to an error page */
      return (this.responseRedirect());
  }
};

export default ProtectedRoute;
