import React, { Component } from "react";

import STRINGS from "../../strings/quiz_strings";

const CHOICE = STRINGS.CHOICE;
const QUESTION = STRINGS.QUESTION;
const BUTTON = STRINGS.BUTTON;

class QuizQuestionController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question_id: this.props.question_id,
      question_image: this.props.question_image,
      choices: props.choices.map(c => {
        return {
          id: c.id,
          choice: c.choice,
          image: c.image,
          isSelected: false
        };
      })
    };

    this.clickChoice = this.clickChoice.bind(this);
  }

  // When clicking on choice, update state of question
  clickChoice(id, e) {
    // Toggle selected choice in array
    let choices = this.state.choices.map(c => {
      // The id is a string so we need to do a weak comparison here with `==`
      // If the choice was selected, then we set isSelected to true while
      // setting all other choices to false (since they are radio buttons)
      if(c.id == id) {
        return {
          id: c.id,
          choice: c.choice,
          isSelected: true,
          image: c.image,
        }
      } else {
        return {
          id: c.id,
          choice: c.choice,
          isSelected: false,
          image: c.image,
        }
      }
    });

    // For each choice, we call the parent's changeCount method
    choices.forEach(c => {
      this.props.changeCount(this.state.question_id, c.id, c.isSelected);
    })

    this.setState({ choices: choices });
  }

  render() {
    return (
      <div className="">
        {/* Question text */}
        <div className={QUESTION.TEXT_CLASS} id={this.props.id}>
          <b>Question: </b>{this.props.questionText}
          <div>
            <img src={this.props.question_image}/>
          </div>
        </div>

        {/* List of answers to choose */}
        <ul className="quiz-choice-list">
          {
            this.state.choices.map(c => {
              const choiceId = 
                `${QUESTION.PLAIN_TEXT}${this.state.question_id}${CHOICE.PLAIN_TEXT}${c.id}`;
              const buttonId = `${BUTTON.PLAIN_TEXT}${choiceId}`;

              // Toggle styling when answer is selected
              let classes = 
                `${CHOICE.CONTAINER_CLASS} 
                ${c.isSelected ? CHOICE.ON_SELECT_CLASS : ""}`;

              return(
                <li 
                key={c.id}
                id={choiceId}
                className={classes}
                onClick={(e) => this.clickChoice(c.id, e)}>
                  {/* Layout for image multiple choice */}
                  {c.image != null && 
                    <div>
                      <div>
                        <img src={c.image}/> 
                      </div>
                                        
                      <div>
                        {c.choice}
                      </div>
                      
                      <input
                        id={c.id}
                        name={this.props.id}
                        type="radio"
                        checked={c.isSelected}
                        onChange={(e) => this.clickChoice(c.id, e)}
                      />
                    </div>
                  }

                  {/* Layout for text only multiple choice */}
                  {c.image === null &&
                    <div className="text-choice">
                      <input
                        id={c.id}
                        name={this.props.id}
                        type="radio"
                        checked={c.isSelected}
                        onChange={(e) => this.clickChoice(c.id, e)}
                      />
                      {c.choice}
                    </div>
                  }
                </li>
              );
            })
          }
        </ul> 
      </div>
    );
  }
};

export default QuizQuestionController;
