import React, { Component } from 'react';
import './interactive_body_chart.css';
import BODY_STRINGS from '../../strings/body_strings';
import BODY_COLOUR_STRINGS from '../../strings/body_colour_strings';

export default class BodyChart extends Component {

  // Called when a bubble is clicked. When clicked, set parent component
  // InteractiveBodyChart's "selected" state to the clicked body part.
  clickPart(partLabel) {
    this.props.changeSelected(partLabel);
  }

  // Called when a bubble is clicked. When clicked, set the colour of that 
  // click zone to the 'selected' state colour.
  partColour(partLabel) {
    let colour;

    // Part is selected, render as selected
    if (this.props.selected === partLabel)
    {
      colour = BODY_COLOUR_STRINGS.CLICK_SELECTED; // Green
    }
    // Else, render as un-selected
    else
    {
      colour = BODY_COLOUR_STRINGS.CLICK_NORMAL; // Pink
    }

    return colour;
  }

  render() {
    return (
      // SVG body chart. Normally this should be in another file, 
      // but in this case it must be in-line for the on-click functions to work.
      
      <div className="cell-body-chart" data-testid = {BODY_STRINGS.BODY_CHART}>
        <svg 
          version="1.1" 
          xmlns="http://www.w3.org/2000/svg" 
          x="0px" 
          y="0px" 
          viewBox="0 0 375.8 555.4"
        >
          <defs>
            {/* Highlight filter, for on-hover. Increases brightness. */}
            <filter id="clickableHighlight">
              <feComponentTransfer>
                <feFuncR type="linear" slope="1.3"/>
                <feFuncG type="linear" slope="1.3"/>
                <feFuncB type="linear" slope="1.3"/>
              </feComponentTransfer>
            </filter>
          </defs>

          {/* Background */}
          <g>
            <rect onClick={(e) => this.clickPart(BODY_STRINGS.DEFAULT)} fillOpacity="0" id="canvas_background" height="719" width="426" y="-1" x="-1"/>
            <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
            <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%"/>
            </g>
          </g>

          {/* Human body */}
          <g id="Body-chart">
            <g>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE}
                d="M319.9,289.5c-0.5,0.2-0.3,0.7-0.4,1c-1.7,7-5,13.1-9.5,18.7c-2.8,3.5-6.6,4.2-10.7,3.9c-2.9-0.2-4.3-2.6-3.7-5.8
                c0.3-1.5,1-2.9,1.7-4.3c1.5-2.8,3.1-5.5,4.3-8.4c1.3-3.2,2.3-6.4,1.5-9.9c-0.2-0.9-0.5-1.8-1-2.7c-0.4,0-0.5,0.4-0.6,0.6
                c-1.5,2.6-2.7,5.2-3.2,8.2c-0.2,1-0.5,1.9-1.1,2.8s-1.5,1.4-2.6,1.1c-1.2-0.3-1.9-1.2-2-2.4c-0.1-1.1,0-2.2,0.3-3.2
                c0.9-2.8,1.4-5.7,2.2-8.5c0.7-2.3,1.4-4.7,3.2-6.5c0.3-0.3,0.3-0.7,0.4-1.1c0.4-3.3,0.2-6.5-1.4-9.5
                c-6.2-11.8-12.9-23.5-19.4-35.2c-1-1.7-1.9-3.5-2.9-5.2c-3-4.8-4.3-9.9-4.6-15.5c-0.2-4.3-0.7-8.6-1-12.9c-0.1-1.7-0.9-3-2.5-3.8
                c-1.3-0.6-2.3-1.7-3.2-2.8c-3.9-4.8-7.2-10-10.5-15.2c-4-6.3-7.9-12.8-11.8-19.1c-0.2-0.4-0.5-0.7-0.9-1.4
                c-0.1,1.1-0.2,1.9-0.3,2.7c-0.8,9.3-1.8,18.5-3.6,27.6c-0.9,4.5-2.1,8.8-3.9,13c-1.8,4.2-2.2,8.8-2.6,13.3
                c-0.7,9.4-0.4,18.7,0.2,28.1c0,0.4,0.1,0.9,0.3,1.2c5.6,10.8,8.6,22.4,9.4,34.6c0.5,6.9,2,13.6,3.5,20.4c3.6,16.1,7,32.2,9.4,48.5
                c1.4,9.8,2.6,19.6,3.7,29.4c0.4,3.3,1.5,6.5,2.2,9.8c3.6,15.7,6.7,31.6,9.2,47.5c2.1,13.1,3.9,26.2,5.4,39.4
                c1.5,13.1,2.6,26.2,3.4,39.3c0,0.6,0.2,1.1,0.4,1.6c1.2,2.4,2.3,4.9,3.5,7.3c2.1,4.3,4.7,8.1,8.2,11.4c3.4,3.2,6.5,6.6,9.4,10.4
                c1.3,1.7,2.5,3.4,3.3,5.4c1,2.5,0.6,3.9-1.5,5.6c-1.4,1.2-3.1,1.9-4.9,2.5c-3.2,1-6.4,0.9-9.7,0.9c-4.6-0.4-9.2-1-13.8-1.8
                c-3.2-0.6-6.4-1.3-9.6-2c-0.4-0.1-0.8-0.2-1.1-0.4c-2.5-1.4-3.5-3.8-4.4-6.3c-0.3-0.8-0.5-1.3-1.6-1.1c-2.1,0.3-3.6-1.1-3.9-3.3
                c-0.2-1.8,0-3.5,0.4-5.2c0.8-3.2,1.6-6.5,2.3-9.8c0.3-1.2,0-2.3-0.4-3.4c-1-2.4-0.8-4.6,0.7-6.7c1.7-2.2,1.5-4.4,0.5-7
                c-10-28-19.9-56-29.7-84c-0.8-2.2-0.6-4.7-0.7-7c-0.6-8.7-1.2-17.4-1.8-26.1c0-0.7-0.3-1.3-0.6-1.9c-8.5-18.4-16.5-37.1-23.2-56.3
                c-4-11.4-7.6-23-10-34.9c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.4,0-0.8-0.2-1.2l0,0c-0.2-0.2-0.2-0.5,0-0.8c-0.4,0.4-0.3,0.9-0.5,1.3
                c-3.3,16.3-8.6,32-14.5,47.5c-5.7,15.2-12.1,30-19,44.7c-0.3,0.7-0.5,1.5-0.6,2.3c-0.7,10.5-1.4,20.9-2.1,31.4
                c0,0.5-0.2,0.9-0.4,1.4c-10.2,28.8-20.4,57.7-30.6,86.5c-0.3,0.8-0.3,1.4,0.2,2.1c0.5,0.9,0.9,1.9,1.5,2.7
                c1.3,1.8,1.5,3.7,0.6,5.7c-1.3,2.8-0.2,5.5,0.4,8.3c0.5,2.4,1.2,4.7,1.7,7.1c0.3,1.4,0.3,2.8,0,4.2c-0.5,2-2,3-4,2.8
                c-0.8-0.1-1,0.1-1.2,0.8c-0.4,1.4-1,2.8-1.8,4c-0.9,1.6-2.2,2.6-4,3c-6.6,1.5-13.2,2.7-19.9,3.5c-1,0.3-2.2-0.1-3.2,0.4
                c-2.9,0.1-5.9,0-8.7-0.6c-2.4-0.7-4.7-1.6-6.6-3.4c-1.1-1-1.4-2.1-1.2-3.6c0.4-1.9,1.3-3.5,2.4-5c2.9-4.3,6.4-8.2,10.2-11.7
                c6-5.5,9.1-12.6,12.4-19.7c0.5-1,0.3-2.3,0.3-3.4c0.8-13.1,2-26.1,3.5-39.1c2.2-19.3,5.1-38.5,8.7-57.5c2.3-12,4.9-23.9,7.7-35.7
                c0.8-3.2,0.8-6.6,1.2-10c1.6-14.2,3.7-28.4,6.4-42.4c2.2-11.7,5-23.3,7.4-34.9c1.2-5.7,1.6-11.5,2.3-17.3c1.1-10.2,4-20,8.7-29.1
                c0.3-0.6,0.5-1.2,0.6-1.9c0.6-9,0.9-17.9,0.3-26.9c-0.4-5.6-1.2-11.1-3.5-16.4c-2.4-5.5-3.2-11.4-4.2-17.3
                c-1.2-7.8-2-15.6-2.7-23.6c-0.5,0.1-0.6,0.6-0.8,0.9c-5.6,9.1-11.1,18.3-17,27.3c-1.6,2.4-3.3,4.8-5.1,7c-1.1,1.4-2.3,2.6-3.9,3.4
                c-1.4,0.7-1.8,2.1-2,3.6c-0.4,5.4-0.9,10.9-1.4,16.3c-0.3,3.8-1.6,7.3-3.5,10.6c-7.5,13.4-15,26.7-22.3,40.2
                c-1.8,3.4-2.7,6.8-2.2,10.7c0.1,0.7,0.3,1.4,0.8,1.9c1,1,1.5,2.3,2,3.6c1.4,4,2.2,8.2,3.3,12.3c0.3,1.3,0.4,2.7-1,3.7
                c-1.3,0.9-2.6,0.7-3.5-0.6c-0.6-0.9-0.9-1.9-1.1-2.9c-0.6-3.1-1.9-6-3.7-8.8c-0.9,1.3-1.2,2.7-1.3,4.1c-0.3,2.9,0.5,5.7,1.6,8.4
                c1.2,2.8,2.7,5.3,4.1,8c0.9,1.6,1.7,3.2,2.1,5.1c0.7,3.6-1.1,5.8-4.8,5.9c-4.2,0-7.7-1.3-10.4-4.8c-4.3-5.8-7.6-11.9-8.9-19.1
                c-0.5-2.8,0-5.6,0.4-8.4c1.7-11.8,3.7-23.5,6.1-35.2c3-14.6,7.8-28.7,13.1-42.7c3.4-9,7.1-17.9,11.2-26.7c0.7-1.4,0.5-3,0.8-4.4
                c1.4-8.9,4.5-17.1,8.2-25.2c4.3-9.4,9.3-18.4,14.9-27.1c0.8-1.2,1.2-2.4,1.4-3.9c0.3-2.3,0.8-4.5,1.6-6.6c2-5.5,5.6-9.7,11.1-11.9
                c6-2.3,11.9-4.7,18.1-6.4c3.3-0.9,6.6-1.4,10-1.8c0.7-0.1,1.3-0.3,1.9-0.7c4.3-2.9,8.9-5.2,14.1-6.2c0.7-0.1,1.5-0.3,2.2-0.3
                c0.8,0,0.9-0.3,0.9-1c0-0.8,0-1.5,0-2.3c0.3-3.3-0.3-6.2-2.8-8.6c-1.2-1.1-1.8-2.6-2.3-4.1c-2.2-6.6-4.5-13.3-6.7-19.9
                c-3.7-11.4,0-26.5,8.5-34.6c5.2-4.9,11.5-7.7,18.6-7.6c9.3,0.2,17.2,4.2,22.2,12.2c6.1,9.7,7.9,20.1,4.2,31.2
                c-1.8,5.5-3.8,10.9-5.5,16.4c-1,3.2-2.5,6.1-4.8,8.6c-0.7,0.7-1,1.5-1,2.5c0,2.1,0,4.1,0,6.2c0,0.5,0,0.9,0.7,0.9
                c6.1,0.5,11.5,3.1,16.5,6.5c0.6,0.4,1.1,0.6,1.9,0.7c5.3,0.7,10.6,1.4,15.6,3.5c2.9,1.2,5.8,2.2,8.8,3.1c10,3,15.2,9.9,16.6,20.1
                c0.2,1.4,0.6,2.6,1.4,3.7c7.4,11.6,14,23.6,18.9,36.6c2.3,6.1,4.1,12.2,4.6,18.7c0.1,0.7,0.4,1.2,0.6,1.8
                c8.5,18.9,16,38.2,21.5,58.2c2.6,9.5,4.1,19.1,5.9,28.8c1.2,6.4,2.2,12.9,3.1,19.4c0,0.2-0.1,0.5,0.3,0.6c0,0.4,0,0.8,0,1.2
                c-0.2,1.3-0.2,2.6,0,3.8C319.9,288.9,319.9,289.2,319.9,289.5z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M261.3,547.8c7.8,1.8,15.6,3.4,23.7,4c0.2,0,0.5,0.1,0.7,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.1
                c-0.5,0-1,0-1.4,0c-1-0.5-2.2-0.3-3.3-0.4c-5-0.6-9.9-1.5-14.7-2.5c-1.3-0.3-2.6-0.6-3.9-0.9C261.9,548.3,261.5,548.3,261.3,547.8
                z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M285.7,552.2c0-0.1,0-0.2,0-0.3c3.3,0,6.6-0.1,9.8-0.8c-0.9,0.6-2,0.6-3,0.8c-0.4,0.1-0.9-0.1-1.3,0.2
                C289.4,552.2,287.5,552.2,285.7,552.2z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M82.5,551.4c2.9,0.4,5.8,0.6,8.7,0.5c0.3-0.1,0.6-0.2,0.9,0.1c0,0.1,0,0.1-0.1,0.2c-2.1,0-4.1,0-6.2,0
                C84.8,551.7,83.5,552.2,82.5,551.4z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M319.9,288.6c-0.4-1.3-0.4-2.6,0-3.8C319.9,286,319.9,287.3,319.9,288.6z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M92.1,552c-0.3,0-0.6-0.1-0.9-0.1c1.1-0.2,2.1-0.2,3.2-0.2C93.7,552.1,92.9,551.9,92.1,552z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M188.2,293.8c-0.1-0.5,0.1-0.9,0.3-1.3c0.3,0.2,0.2,0.5,0.2,0.8C188.5,293.5,188.3,293.7,188.2,293.8z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M188.6,293.3c0.3,0.4,0.3,0.8,0.2,1.2C188.6,294.2,188.6,293.8,188.6,293.3z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_FILL} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M238.9,153.9c-1,12.2-2.2,24.4-5.5,36.2c-0.8,3-2.4,5.7-3.1,8.8c-1.5,6.6-1.8,13.3-1.8,20
                c-0.1,6.2,0.2,12.4,0.7,18.6c0,0.5,0.2,0.9,0.4,1.4c5.3,10.3,8.3,21.2,9.2,32.8c0.6,7.8,2.2,15.5,3.9,23.1
                c3.7,16.3,7,32.6,9.4,49.2c1.3,9.2,2.3,18.5,3.4,27.8c0.4,3.3,1.5,6.5,2.2,9.8c3.6,15.7,6.7,31.6,9.2,47.5
                c2.1,13.1,3.9,26.3,5.4,39.5c1.4,12.4,2.5,24.7,3.2,37.2c0.1,1.8,0.4,3.5,1.3,5.2s1.7,3.5,2.6,5.3c2.3,4.9,5.3,9.2,9.3,12.9
                c3.4,3.2,6.5,6.6,9.3,10.4c1.1,1.5,2.1,3,2.7,4.8c0.5,1.6,0.3,2.2-0.9,3.3c-2.3,2-5.1,2.7-8,3c-4.8,0.5-9.5-0.1-14.2-0.8
                c-4.8-0.7-9.6-1.7-14.4-2.8c-1.5-0.3-2.5-1.1-3.2-2.4c-1.5-2.8-2.4-5.8-2.7-9c0-0.4,0.1-1.1-0.8-1c-0.6,0.1-0.9,0.2-0.8,0.9
                c0.2,0.9,0.2,1.9,0.4,2.8c0.1,0.7-0.1,0.8-0.7,0.9c-1.2,0-2-0.6-2.2-1.9c-0.2-1.1-0.2-2.2,0-3.3c0.7-3,1.4-6,2.2-9.2
                c0.3,1,0.8,0.8,1.4,0.7c0.7-0.2,0.4-0.7,0.3-1.1c-0.2-2.2-0.4-4.4-0.5-6.7c0-0.4,0-0.8-0.7-0.8c-1.1,0-1.5-0.8-0.9-1.8
                c0.8-1.6,1.6-3.3,2.5-4.9c0.4-0.8,0.5-1.5,0.2-2.4c-10.2-28.9-20.4-57.8-30.7-86.7c-0.5-1.4-0.5-2.9-0.6-4.4
                c-0.7-9.7-1.3-19.4-2-29.1c0-0.7-0.3-1.2-0.6-1.8c-8.6-18.6-16.7-37.6-23.4-57c-3.9-11.2-7.4-22.5-9.8-34.2
                c-0.3-1.4-0.5-2.9-0.8-4.3c-0.2-0.8,0.1-1.1,0.9-1.3c4.1-1.2,8.1-2.8,11.7-5c2.6-1.6,4.9-3.4,6.5-6c0.2-0.4,0.8-0.8,0.1-1.4
                s-1-0.2-1.5,0.1c-5.1,4-10.6,7.5-16.4,10.4c-2.2,1.1-4,0.7-5.8-0.4c-5.4-3.1-10.8-6.3-15.7-10.3c-0.5-0.4-0.7-0.2-1.1,0.1
                c-0.5,0.3-0.4,0.7-0.2,1.1c1.1,2,2.6,3.5,4.4,4.8c4.2,3.1,8.9,5,13.9,6.5c1,0.3,1.2,0.7,1,1.7c-2.2,12.8-6.1,25.1-10.3,37.4
                c-6.8,19.7-14.9,38.8-23.6,57.7c-0.4,0.9-0.6,1.8-0.7,2.7c-0.7,10.3-1.4,20.7-2.1,31c0,0.6-0.2,1.1-0.4,1.6
                c-10.2,28.8-20.4,57.7-30.6,86.5c-0.4,1.1-0.4,1.9,0.2,2.9c0.8,1.4,1.5,2.9,2.3,4.3c0.7,1.4,0.5,1.9-1,2.1
                c-0.6,0.1-0.5,0.5-0.5,0.9c-0.2,2.2-0.4,4.4-0.5,6.7c0,0.3-0.3,0.9,0.3,1c0.5,0.1,1.1,0.6,1.4-0.5c0.7,3.3,1.8,6.4,2.3,9.7
                c0.1,1,0.2,2.1-0.2,3.1c-0.2,0.7-0.6,1.2-1.4,1.4c-1.3,0.3-1.6,0-1.4-1.2c0.1-0.8,0.2-1.5,0.3-2.3c0.1-0.6,0-0.9-0.7-1
                c-0.8-0.1-0.8,0.4-0.8,0.9c-0.2,3-1.1,5.9-2.4,8.6c-0.8,1.7-2.1,2.7-4,3.1c-7.4,1.6-14.9,3.1-22.5,3.5c-3.2,0.2-6.3,0.1-9.4-0.8
                c-1.8-0.5-3.4-1.4-4.7-2.7c-0.6-0.6-0.8-1.2-0.7-2c0.2-1.4,0.9-2.6,1.6-3.7c1.9-3,4.3-5.7,6.8-8.4c1.9-2,3.8-4,5.7-5.9
                c2.8-2.7,4.7-5.9,6.5-9.2c1-2,1.9-4.1,3-6c2.1-3.3,1.7-7.1,2-10.7c1-14,2.3-28,4-41.9c2-16.7,4.6-33.4,7.7-49.9
                c2.3-12,4.8-24,7.7-35.8c0.8-3.4,0.9-6.9,1.3-10.4c1.6-14.3,3.7-28.4,6.4-42.5c2.2-11.7,5-23.2,7.4-34.8
                c1.2-5.8,1.7-11.6,2.3-17.4c1.1-10.2,4-20,8.7-29.1c0.3-0.6,0.5-1.1,0.6-1.8c0.5-8.1,0.9-16.2,0.5-24.3c-0.3-5.8-0.4-11.7-2.7-17
                c-3.9-9.3-5.1-19.2-6.4-29.1c-0.5-4.1-1-8.2-1.2-12.3c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.2,0-0.4,0.2-0.5c0.3,0,0.2,0.3,0.4,0.4
                c2,4.1,4.3,8,7.4,11.3c7.3,7.8,16.4,10.6,26.8,9.6c4.8-0.5,9.4-1.8,14-2.8c0.9-0.2,1.8-0.2,2.7,0c4.6,0.9,9,2.2,13.7,2.7
                c13.8,1.5,24.4-3.7,31.8-15.4c1.3-2.1,2.4-4.2,3.4-6.5C239.3,152.8,238.9,153.4,238.9,153.9z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_FILL} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M74.9,279.1c-2.1,2.7-3,5.6-2.9,8.9c0.2,3.2,1.2,6.1,2.6,8.9s2.9,5.5,4.3,8.3c0.7,1.4,1.2,2.9,0.9,4.5
                c-0.2,1.1-0.8,1.8-1.9,1.9c-3.3,0.3-6.6,0.3-9.1-2.4c-5.4-5.9-9-12.8-10.3-20.7c-0.5-2.9,0.2-5.7,0.6-8.6
                c1.7-11.4,3.6-22.7,5.9-33.9c3-14.6,7.8-28.7,13.1-42.6c3.4-9,7.2-18,11.2-26.8c0.6-1.4,0.5-2.9,0.7-4.3c1.3-8.8,4.5-17,8.1-25
                c4.3-9.5,9.4-18.5,15-27.3c0.7-1.1,1.2-2.3,1.3-3.6c0.2-1.8,0.6-3.5,1.1-5.3c1.9-6.3,5.8-10.9,12-13c5.6-1.9,10.9-4.4,16.7-5.8
                c3.4-0.9,6.8-1.4,10.2-1.8c0.7-0.1,1.2-0.3,1.7-0.7c4.3-3,8.9-5.3,14.1-6.2c2.2-0.4,2.2-0.4,1.9,1.8c-0.3,2.4-0.5,4.8-0.8,7.1
                c-0.1,0.6-0.1,1,0.7,1.2c0.9,0.1,0.8-0.5,0.9-0.9c1-5,1.5-10,1.5-15.1c0-0.6,0-1.3,0-2.3c0.5,0.5,0.7,0.9,1,1.2
                c2.7,2.9,5.5,5.9,8.8,8.2c1.2,0.9,2.5,1.5,4.2,1.4c1.2-0.1,2.2-0.5,3.2-1.2c2.8-1.9,5.2-4.3,7.6-6.7c0.8-0.9,1.6-1.7,2.7-2.8
                c0,0.8,0,1.4,0,2c-0.1,5.2,0.4,10.3,1.5,15.3c0.1,0.5,0,1.1,0.9,0.9c0.8-0.1,0.8-0.5,0.7-1.1c-0.3-2.7-0.6-5.5-0.9-8.2
                c-0.1-0.8,0.1-1,0.9-0.9c3.9,0.5,7.5,1.9,11,3.8c0.3,0.2,0.7,0.3,0.9,0.5c2.8,2.3,6.2,3,9.7,3.4c4.5,0.6,9,1.7,13.2,3.7
                c2.2,1,4.5,1.5,6.8,2.3c6.2,2,11,5.5,13.5,11.7c1,2.5,1.6,5,1.9,7.6c0.1,1.2,0.5,2.2,1.1,3.2c8,12.5,15.1,25.4,20,39.4
                c1.8,5.1,3.2,10.4,3.6,15.8c0,0.7,0.3,1.2,0.5,1.8c8.6,19,16.1,38.4,21.7,58.5c2.5,9,4,18.2,5.6,27.4c1.3,7.3,2.6,14.6,3.5,22
                c0.3,2.4,0,4.8-0.7,7.1c-1.8,6.1-4.7,11.5-8.7,16.5c-2.5,3.1-5.8,3.6-9.3,3.5c-1.8-0.1-2.6-1-2.7-2.9c-0.1-1.4,0.5-2.6,1.1-3.9
                c1.6-3.3,3.6-6.4,5-9.8c1.5-3.5,2.4-7,1.5-10.8c-0.4-1.9-1.3-3.6-2.6-5.2c-2.3,3.4-4.2,6.9-5.1,10.9c-0.1,0.7-0.3,1.3-0.5,1.9
                s-0.5,1.4-1.4,1.2c-0.9-0.2-1-0.9-0.9-1.7c0.1-0.7,0.1-1.4,0.3-2c0.9-2.9,1.4-5.9,2.3-8.8c0.6-2.1,1.3-4.3,3-5.8
                c0.3-0.2,0.3-0.6,0.3-0.9c0.7-4,0.4-7.9-1.6-11.6c-7.1-13.3-14.4-26.4-21.9-39.5c-2.9-5-4.5-10.2-4.7-16c-0.2-4.2-0.6-8.4-1-12.6
                c-0.2-1.8-0.9-3.4-2.5-4.3c-1.9-1-3.2-2.5-4.4-4.1c-3.4-4.3-6.4-9-9.3-13.6c-4.7-7.6-9.3-15.2-14-22.8c-0.4-0.7-0.5-1.3-0.3-2.1
                c0.2-0.4,0.7-1-0.3-1.3c-0.9-0.3-1,0.3-1.1,0.9c-1.9,6.4-4.9,12.3-9.6,17.1c-6.8,7-15.2,9.6-24.8,8.8c-5-0.4-9.8-1.9-14.8-2.9
                c-0.9-0.2-1.7-0.3-2.7,0c-4.7,1-9.3,2.4-14.2,2.8c-12.9,1.3-22.8-3.6-29.8-14.4c-2.3-3.6-4-7.5-5.2-11.6c-0.2-0.5-0.2-1-1-0.8
                s-0.6,0.7-0.4,1.1c0.4,1,0.2,1.8-0.4,2.6c-6.5,10.6-12.8,21.2-19.7,31.5c-1.3,2-2.7,3.9-4.3,5.8c-1,1.1-2,2.1-3.3,2.9
                c-1.7,0.9-2.6,2.5-2.7,4.5c-0.4,5.4-0.9,10.8-1.3,16.2c-0.3,3.7-1.5,7.1-3.4,10.4c-7.5,13.4-15.1,26.9-22.4,40.4
                c-2,3.6-2.9,7.4-2.3,11.5c0.1,1,0.3,1.9,1.1,2.6c1,0.9,1.4,2.2,1.8,3.5c1.3,3.8,2.1,7.8,3.1,11.8c0.1,0.4,0.1,0.8,0.1,1.2
                c0,0.6-0.3,1-0.9,1.2c-0.7,0.2-0.9-0.3-1.2-0.7c-0.4-0.7-0.6-1.4-0.7-2.1C79.3,286.2,77.3,282.7,74.9,279.1z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_FILL} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M161.5,36.8c0.2-6.2,1.5-12.1,4.4-17.6c4.7-8.8,15-14.8,23.8-13.8c11.5,1.3,19.2,7.5,23.2,18.3
                c2.9,8,3.4,16.1,0.3,24.3c-2,5.2-3.6,10.5-5.3,15.8c-0.8,2.5-2.1,4.8-3.8,6.9c-3.5,4.2-7,8.4-11.3,11.8c-4,3.2-4.8,3.2-8.8,0
                c-5-4-9.2-9-13-14.1c-0.5-0.7-0.9-1.5-1.2-2.3c-2.5-7.3-5.1-14.7-7.4-22.1C161.8,41.7,161.5,39.2,161.5,36.8z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M138.7,153.4c-0.2,0.1-0.4-0.6-0.5,0c-0.1,0.4,0,0.8-0.1,1.3c-0.3-0.9-0.3-1.8-0.2-3.1
                C138.4,152.3,138.6,152.8,138.7,153.4z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M173.2,280.9c1,0.4,1.5,0.8,2.1,1.2C174.5,282.2,174.5,282.2,173.2,280.9z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M201.7,282.1c0.6-0.4,1.2-0.8,2-1.3C203.2,281.8,202.5,282,201.7,282.1z"/>
              <path fill={BODY_COLOUR_STRINGS.BODY_LINE} stroke={BODY_COLOUR_STRINGS.BODY_LINE} d="M238.9,153.9c-0.3-0.5,0.2-1-0.2-1.4c-0.1-0.3,0.2-0.5,0.4-1C239.1,152.5,239.1,153.2,238.9,153.9z"/>
            </g>
          </g>
          
          {/* Clickable zones */}
          <g id="Click_zones" className="body-part">

            <g onClick={() => this.clickPart(BODY_STRINGS.WAIST)} data-testid={BODY_STRINGS.CLICK_WAIST}>
              <circle fill={this.partColour(BODY_STRINGS.WAIST)} cx="228.1" cy="229.9" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.WAIST)} points="227.8,229.5 281.6,212.4 284.7,237.1 	"/>
              <circle fill={this.partColour(BODY_STRINGS.WAIST)} cx="285.8" cy="222.6" r="20.9"/>

              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M284.4,227.9l-0.1-1c-0.2-2.1,0.5-4.4,2.4-6.8c1.8-2.1,2.7-3.6,2.7-5.4c0-2-1.3-3.3-3.7-3.4
                  c-1.4,0-3,0.5-4,1.2l-0.9-2.5c1.3-0.9,3.5-1.6,5.6-1.6c4.5,0,6.5,2.8,6.5,5.8c0,2.7-1.5,4.6-3.4,6.8c-1.7,2-2.4,3.8-2.2,5.8v1
                  L284.4,227.9L284.4,227.9z M283.5,233.3c0-1.4,1-2.5,2.4-2.5s2.3,1,2.3,2.5c0,1.4-0.9,2.4-2.4,2.4
                  C284.5,235.8,283.5,234.7,283.5,233.3z"/>
              </g>
            </g>

            <g onClick={() => this.clickPart(BODY_STRINGS.HEAD)} data-testid={BODY_STRINGS.CLICK_HEAD}>
              <circle onClick={(e) => this.clickPart(BODY_STRINGS.HEAD)} fill={this.partColour(BODY_STRINGS.HEAD)} cx="201.5" cy="79.2" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.HEAD)} points="201.3,78.7 255,61.7 258.2,86.4 	"/>
              <circle fill={this.partColour(BODY_STRINGS.HEAD)} cx="259.2" cy="71.9" r="20.9"/>
              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M257.8,77.1l-0.1-1c-0.2-2.1,0.5-4.4,2.4-6.8c1.8-2.1,2.7-3.6,2.7-5.4c0-2-1.3-3.3-3.7-3.4
                  c-1.4,0-3,0.5-4,1.2l-0.9-2.5c1.3-0.9,3.5-1.6,5.6-1.6c4.5,0,6.5,2.8,6.5,5.8c0,2.7-1.5,4.6-3.4,6.8c-1.7,2-2.4,3.8-2.2,5.8v1
                  L257.8,77.1L257.8,77.1z M257,82.6c0-1.4,1-2.5,2.4-2.5s2.3,1,2.3,2.5c0,1.4-0.9,2.4-2.4,2.4C257.9,85,257,84,257,82.6z"/>
              </g>
            </g>
            
            <g onClick={() => this.clickPart(BODY_STRINGS.ARM)} data-testid={BODY_STRINGS.CLICK_ARM}>
              <circle fill={this.partColour(BODY_STRINGS.ARM)} cx="96.1" cy="193.2" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.ARM)} points="96.4,193.6 42.6,210.7 39.5,186 	"/>
              <circle fill={this.partColour(BODY_STRINGS.ARM)} cx="38.4" cy="200.5" r="20.9"/>
              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M34.7,206.7l-0.1-1c-0.2-2.1,0.5-4.4,2.4-6.8c1.8-2.1,2.7-3.6,2.7-5.4c0-2-1.3-3.3-3.7-3.4
                  c-1.4,0-3,0.5-4,1.2l-0.9-2.5c1.3-0.9,3.5-1.6,5.6-1.6c4.5,0,6.5,2.8,6.5,5.8c0,2.7-1.5,4.6-3.4,6.8c-1.7,2-2.4,3.8-2.2,5.8v1
                  L34.7,206.7L34.7,206.7z M33.9,212.2c0-1.4,1-2.5,2.4-2.5s2.3,1,2.3,2.5c0,1.4-0.9,2.4-2.4,2.4C34.8,214.6,33.9,213.6,33.9,212.2z
                  "/>
              </g>
            </g>
            
            <g onClick={() => this.clickPart(BODY_STRINGS.SHOULDER)} data-testid={BODY_STRINGS.CLICK_SHOULDER}>
              <circle fill={this.partColour(BODY_STRINGS.SHOULDER)} cx="131.8" cy="107.7" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.SHOULDER)} points="131.9,108.2 75.5,107.4 80.3,83 	"/>
              <circle fill={this.partColour(BODY_STRINGS.SHOULDER)} cx="74.7" cy="96.5" r="20.9"/>
              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M72.9,103.2l-0.1-1c-0.2-2.1,0.5-4.4,2.4-6.8C77,93.3,78,91.8,78,90c0-2-1.3-3.3-3.7-3.4c-1.4,0-3,0.5-4,1.2
                  l-0.9-2.5c1.3-0.9,3.5-1.6,5.6-1.6c4.5,0,6.5,2.8,6.5,5.8c0,2.7-1.5,4.6-3.4,6.8c-1.7,2-2.4,3.8-2.2,5.8v1L72.9,103.2L72.9,103.2z
                  M72,108.7c0-1.4,1-2.5,2.4-2.5s2.3,1,2.3,2.5c0,1.4-0.9,2.4-2.4,2.4C73,111.1,72,110.1,72,108.7z"/>
              </g>
            </g>

            <g onClick={() => this.clickPart(BODY_STRINGS.THIGH)} data-testid={BODY_STRINGS.CLICK_THIGH}>
              <circle fill={this.partColour(BODY_STRINGS.THIGH)} cx="125.1" cy="372.8" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.THIGH)} points="125,373.3 71.7,354.8 83.9,333.1 	"/>
              <circle fill={this.partColour(BODY_STRINGS.THIGH)} cx="74.4" cy="344.2" r="20.9"/>
              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M71.9,349.3l-0.1-1c-0.2-2.1,0.5-4.4,2.4-6.8c1.8-2.1,2.7-3.6,2.7-5.4c0-2-1.3-3.3-3.7-3.4
                  c-1.4,0-3,0.5-4,1.2l-0.9-2.5c1.3-0.9,3.5-1.6,5.6-1.6c4.5,0,6.5,2.8,6.5,5.8c0,2.7-1.5,4.6-3.4,6.8c-1.7,2-2.4,3.8-2.2,5.8v1
                  h-2.9V349.3z M71,354.8c0-1.4,1-2.5,2.4-2.5s2.3,1,2.3,2.5c0,1.4-0.9,2.4-2.4,2.4C71.9,357.2,71,356.2,71,354.8z"/>
              </g>
            </g>
            
            <g onClick={() => this.clickPart(BODY_STRINGS.ANKLE)} data-testid={BODY_STRINGS.CLICK_ANKLE}>
              <circle fill={this.partColour(BODY_STRINGS.ANKLE)} cx="101.8" cy="513" r="10"/>
              <polygon fill={this.partColour(BODY_STRINGS.ANKLE)} points="101.6,513.5 52.1,486.4 67.7,467.1 	"/>
              <circle fill={this.partColour(BODY_STRINGS.ANKLE)} cx="56.5" cy="476.4" r="20.9"/>
              <g>
                <path fill={BODY_COLOUR_STRINGS.QUESTION_MARK_FILL} 
                  d="M52.8,481.5l-0.1-1c-0.2-2.1,0.6-4.4,2.6-6.7c1.8-2,2.8-3.5,2.9-5.3c0-2-1.2-3.4-3.7-3.4c-1.4,0-3,0.4-4,1.1
                  l-0.9-2.5c1.3-0.9,3.6-1.5,5.6-1.4c4.5,0.1,6.5,2.9,6.4,5.9c-0.1,2.7-1.6,4.6-3.5,6.7c-1.8,2-2.4,3.7-2.4,5.7v1L52.8,481.5z
                  M51.8,487c0-1.4,1-2.4,2.4-2.4s2.3,1.1,2.3,2.5s-1,2.4-2.4,2.4C52.7,489.5,51.8,488.4,51.8,487z"/>
              </g>
            </g>

          </g>
          </svg>
      </div>
    );
  }
}