import MODULE_STRINGS from '../../../strings/module_strings';

export default {

  // Imports and maps each file to an array by its name. Separated into this file
  // so that webpack's require.context function can be mocked by mocking this module.
  importBodyChartImages(module) {
    let images = {};
    let files;
    
    // Make sure the specified module is valid. Each case must call
    // require.context with a static string, which is why it's not dynamically
    // built using the switch statement.
    // Uses module 1 if the desired module is invalid.
    switch(module)
    {
      case MODULE_STRINGS.MODULE1:
        files = require.context(('../../../static/module1/interactive_body_chart'), 
          false, 
          /\.png$/
        );
      break;

      case MODULE_STRINGS.MODULE2:
        files = require.context(('../../../static/module2/interactive_body_chart'), 
          false, 
          /\.png$/
        );
      break;

      case MODULE_STRINGS.MODULE3:
        files = require.context(('../../../static/module3/interactive_body_chart'), 
          false, 
          /\.png$/
        );
      break;

      default:
        files = require.context(('../../../static/module1/interactive_body_chart'), 
          false, 
          /\.png$/
        );
      break;
    }

    files.keys().map((item) => { 
      return images[item.replace('./', '')] = files(item); 
    });

    return images;
  }

}