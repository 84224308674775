import React, { Component } from "react";
import DiaryService from '../services/diary.service';
import AUTH_STRINGS from '../strings/auth_strings';
import UserService from '../services/user.service';
import './diary.css';

class Diary extends Component {
  constructor(props) {
    super(props);
      this.state = {
        data: [],
        loaded: false,
        placeholder: "Loading"
      }
  }

	// If the component mounts, run the function to get the diary data from API
  async componentDidMount() {
    await this.getAll();
    await this.checkRedirected();
  }

  componentWillUnmount() {
    this.loaded = false;
  }

  // Get all diary questions and images
  async getAll() {
    try {
      const response = await DiaryService.getAll();
      this.setState({
        data: response.data,
        loaded: true,
      });
    } catch (error) {
      console.log(error);
      let error_msg = undefined;
      if(error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }
      }

      this.setState({ placeholder: error_msg });
    }
  }

  // Check if the diary is compulsory to fill in
  async checkRedirected() {
    try {
      const response = await UserService.checkDiary();
      if(response.data >= 7) {
        alert("You must fill in the diary before continuing to use the rest of the site");
      }
    } catch (error) {
      console.log("Error in checkdiary API call: " + error);
    }
  }

  // This runs when the user submits their diary. It runs a check to see if 
  // all questions have been answered. If not it will prompt the user to answer
  // all questions. Otherwise it will compile the responses in a JSON file and 
  // send to the backend API
  async submitDiary(diary_question_IDs, e) {
    e.preventDefault();
    let responses = [];

    // For each diary question
    diary_question_IDs.forEach((question) => {	
      for (let j=0; j < 4; j++) {
        // Check if the choice is selected and add to JSON file if it is
        var elementName = question + "-" + (j + 1);
        if (document.getElementById(elementName).checked) {
          responses.push(elementName);
        }   
      }
    });

    await DiaryService.submit({data: responses});
    window.location.href = "/";
  }

  // This is the HTML that is sent to the user
    render() {
      // Check if the data has come in or not
      if(typeof this.state.data !== 'undefined' 
          && typeof this.state.data[0] !== 'undefined'){
        // Unpack the data
        let diary_questionset = this.state.data;
        let diary_question_num = this.state.data.length;
        let diary_question_IDs = [];

        // Collect the diary question IDs into an array which will be used for 
        // submission
        for (let i = 0; i < diary_question_num; i++) {
          diary_question_IDs.push(diary_questionset[i].id);
        }

        // Send this data to the user
        return(
          <div className="page_content">
          <h3>There are {diary_question_num} question(s) within the diary</h3>

          <form onSubmit={(e) => {this.submitDiary(diary_question_IDs, e)}}>
          {diary_questionset.map(q => {
            let diary_text = q.description;
            let diary_image = q.image;

            // Define the <li> IDs to be unique (question ID plus choice)
            let option_one_id = q.id + "-1";
            let option_two_id = q.id + "-2";
            let option_three_id = q.id + "-3";
            let option_four_id = q.id + "-4";
            let diary_entry_question_id = "diary_entry_question_" + q.id;

            // Send the image, question and choices to the user
            return(
              <span key={q.id}>
                <b id={diary_entry_question_id}> {diary_text} </b> 
                <div className="diary_question">
                  {diary_image != null &&
                    <img 
                    className="diary_question_image"
                    src={diary_image} 
                    alt="diary_question" 
                  />
                  }

                  <ul className="diary_question_choice">
                    <li key={option_one_id}>
                      <input id={option_one_id} name={q.id} type="radio" required/>
                      A: Did not do this
                    </li>
                    <li key={option_two_id}>
                      <input id={option_two_id} name={q.id}type="radio" required/>
                      B: Did this 1 to 3 days this week
                    </li>
                    <li key={option_three_id}>
                      <input id={option_three_id} name={q.id} type="radio" required/>
                      C: Did this 4 to 6 days this week
                    </li>
                    <li key={option_four_id}>
                      <input id={option_four_id} name={q.id} type="radio" required/>
                      D: Did this everyday
                    </li>
                  </ul>
                </div>

              </span>
            );

          })}
          <input type="submit" value="Submit Diary"/>
          </form>
          </div>	
        );
      }
      else
      {
        return(
          <ul>{this.state.placeholder}</ul>
        );
      }
	}
}

export default Diary;
