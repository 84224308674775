import React, { Component } from "react";
import LogoutService from "../../services/logout.service";

class Logout extends Component {
  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
  }

  async handleLogout() {
    try {
      await LogoutService.logout();
      window.location.href = '/login';
    } catch (error) {
      // If we get here, then blacklisting the tokens failed for some reason.
      // If this happens, we'll just send the users to `/`, because their
      // tokens are deleted anyway
      window.location.href = '/';
    }
  }

  // As soon as the component mounts, we logout
  componentDidMount() {
    this.handleLogout();
  }

  render() {
    return(
      <div data-testid="logout_component">
      </div>
    );
  }
}

export default Logout;

