export default {
    slideContent: [
        // Computers
        [
          {
            id: "slide0",
            title: "Take a break to stand and move around once or twice every 1 hour of technology use",
            contentPath: "https://drive.google.com/file/d/1J9J_4cTXnAnL6skeka3so95s4PcZk33y/preview"
          },
          {
            id: "slide1",
            title: "Perform stretches to relieve muscle tension during each break",
            contentPath: "https://drive.google.com/file/d/15hSIfgwCjkdk7vriGs7iBBJr0UPVrtL3/preview"
          },
          {
            id: "slide2",
            title: "Change your position every few minutes",
            contentPath: "https://drive.google.com/file/d/1IPuE8s_wQS4O_sQ4Gce11iOyPQ2DuMiE/preview"
          },
          {
            id: "slide3",
            title: "Alternate between typing on the keyboard and using the mouse",
            contentPath: "https://drive.google.com/file/d/1hUGvokMQH-jWXCL3v_FXPd0RYdsfXxbY/preview"
          },
          {
            id: "slide4",
            title: "Switch hands every few minutes when using the mouse",
            contentPath: "https://drive.google.com/file/d/1ZdCc2y4wsrO_GRtAv1OdKwxA4mM9JMH7/preview"
          },
        ],
        // Tablets
        [
          {
            id: "slide0",
            title: "Take a break to stand and move around once or twice every 1 hour of technology use",
            contentPath: "https://drive.google.com/file/d/192OWETqVqaM-wjjLg5tnDIGdx0Td1g7q/preview"
          },
          {
            id: "slide1",
            title: "Perform stretches to relieve muscle tension during each break",
            contentPath: "https://drive.google.com/file/d/1LWSd7VT16wQepDpahTC1IKLMkHsLxCtf/preview"
          },
          {
            id: "slide2",
            title: "Change your position every few minutes",
            contentPath: "https://drive.google.com/file/d/1lBlvGDUOluiDD3chf1bi2cGzuQR9nYZH/preview"
          },
          {
            id: "slide3",
            title: "Use thumbs from both your hands to type on the tablet",
            contentPath: "https://drive.google.com/file/d/1zEOEIXArs4W4baAR_WGY4cYHWioWeIlQ/preview"
          },
        ],
        // Smartphones
        [
          {
            id: "slide0",
            title: "Take a break to stand and move around once or twice every 1 hour of technology use",
            contentPath: "https://drive.google.com/file/d/1eAVBomcEoBe6Te9NBELWxpu2LMKO-88w/preview"
          },
          {
            id: "slide1",
            title: "Perform stretches to relieve muscle tension during each break",
            contentPath: "https://drive.google.com/file/d/1Cv4S2cmggFuWPkdBP4fpR2gmeSqzKUr9/preview"
          },
          {
            id: "slide2",
            title: "Change your position every few minutes",
            contentPath: "https://drive.google.com/file/d/1FIlqPBNlOptpHUjIjssUgOkavThDrdR9/preview"
          },
          {
            id: "slide3",
            title: "Use both thumbs to type on the smartphone",
            contentPath: "https://drive.google.com/file/d/1avCPeVfoagtKK0sMWDFiSv_yXdKxqLdQ/preview"
          },
        ],
        // Game consoles
        [
          {
            id: "slide0",
            title: "Take a break to stand and move around once or twice every 1 hour of technology use",
            contentPath: "https://drive.google.com/file/d/1oQ8vDq5rLXTCmwi4Mpzw8Blueatr_nz2/preview"
          },
          {
            id: "slide1",
            title: "Perform stretches to relieve muscle tension during each break",
            contentPath: "https://drive.google.com/file/d/1ReapqpIBbR9yUftt7wCrYMuxLdz9I3zZ/preview"
          },
          {
            id: "slide2",
            title: "Change your position every few minutes",
            contentPath: "https://drive.google.com/file/d/1Xy8wyqBqVrhPgTHirhh9iefp8uAQyj2c/preview"
          },
          {
            id: "slide3",
            title: "Use both thumbs to press the buttons on the controller",
            contentPath: "https://drive.google.com/file/d/1RiuVt9PZesDFHm00xYCZvlaRGDgwdm-5/preview"
          },
        ]
    ]
};
