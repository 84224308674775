import React, { Component } from "react";
import { Link } from 'react-router-dom';
import './learning_three.css';
import taskIcon from '../../../static/module3/learning/task_icon.jpg';
import stretchIcon from '../../../static/module3/learning/stretch_icon.jpg';


class LearningThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="page_content">
        <h2>Module 3- Learning Component- Stretching and Task Variation</h2>
        <a href="/module3">Back to module home</a>
        
        <p className="learning_description">
          When using technology, it is important to correctly stretch the 
          muscles in your body every few minutes and alternate between 
          different tasks. This can help to reduce the risk of pain, strain, or
          discomfort in different parts of the body. 
        </p>
        <p className="learning_description">
          In this section, you will
          find a stretch guide consisting of videos of role models demonstrating 
          how to correctly stretch the muscles in your body when using 
          technology. You may also view videos showing you how to alternate 
          between different tasks when using technology.
        </p>
        <p >Click on any of the icons below to find out more!</p>

        <div className="module_three_grid_container">

          <div>
            <Link to="/module3/learning/stretch-guide">
              <button>
                <div><b>Stretch Guide</b></div>
                <img 
                  className="module_three_cell_image"
                  src={stretchIcon} 
                  alt="Stretch Guide"
                />
              </button>
            </Link>
          </div>

          <div>
            <Link to="/module3/learning/task-variation">
              <button>
                <div><b>Task Variation</b></div>
                <img 
                  className="module_three_cell_image"
                  src={taskIcon} 
                  alt="Task Variation"
                />
              </button>
            </Link>
          </div>

        </div>
      </div>
    );
  }
}

export default LearningThree;