import axios from "../http-common";

class LoginService {
  static async login(credentials) {
    try {
      // Obtain a token using the credentials
      const response = await axios.post("/token/obtain/", credentials);

      // Store the refresh and access tokens into localStorage
      const jwt_tokens = response.data;
      localStorage.setItem('jwt_refresh', jwt_tokens['refresh']);
      localStorage.setItem('jwt_access', jwt_tokens['access']);

      // We need to set this here to prevent the requirement of a page refresh
      // after login
      axios.defaults.headers['Authorization'] = 'JWT ' + jwt_tokens['access'];

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default LoginService;
