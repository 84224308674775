import axios from "../http-common";

class QuestionnaireService {
  // This method is not intended to be used anywhere. It is a placeholder to
  // refer to as a blueprint when writing other methods that make requests to
  // the Django REST API
  static async getAll() {
    try {
      return await axios.get("/questionnaire/");
    } catch (error) {
      throw error; // Let the caller handle any errors
    }
  }

  static async submit(data) {
    try {
      return await axios.post("/questionnaire/submit/", data);
    } catch (error) {
      throw error; // Let the caller handle any errors
    }
  }

  static async isSubmittable(data) {
    try {
      return await axios.post("/questionnaire/submittable/", data);
    }
    catch (error) {
      throw error; // Let the caller handle any errors
    }
  }
}

export default QuestionnaireService;
