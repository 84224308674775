import axios from "../http-common";

class QuizService {
  // This method is not intended to be used anywhere. It is a placeholder to
  // refer to as a blueprint when writing other methods that make requests to
  // the Django REST API
  static async getAll() {
    try {
      return await axios.get("/quiz/");
    } catch (error) {
      throw error; // Let the caller handle any errors
    }
  }

  static async submit(data) {
    try {
      return await axios.post("/quiz/submit/", {data: data});
    } catch (error) {
      throw error;
    }
  }
}

export default QuizService;
