import axios from "../http-common";

class UserService {
  static async getUsers() {
    try {
      // Acquire Auth token from user's local storage and set it to default 
      // header
      const auth_token = localStorage.getItem('jwt_access');
      axios.defaults.headers['Authorization'] = 'JWT ' + auth_token;

      // Obtain list of users
      const response = await axios.get('/user/list/');

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async createUser(userInfo) {
    try {
      const auth_token = localStorage.getItem('jwt_access');
      axios.defaults.headers['Authorization'] = 'JWT ' + auth_token;

      // Need these if checks so that an empty string won't cause a bad request
      if(userInfo.age === '') {
        userInfo.age = null;
      }

      if(userInfo.year === '') {
        userInfo.year = null;
      }

      if(userInfo.postalCode === '') {
        userInfo.postalCode = null;
      }

      // Package data up 
      const user = {user: {username: userInfo.username, 
                           password: userInfo.password,
                           first_name: userInfo.firstName,
                           last_name: userInfo.lastName},
                    age: userInfo.age, 
                    gender: userInfo.gender, 
                    school: userInfo.school,
                    year: userInfo.year,
                    postal_code: userInfo.postalCode,
                    user_type: userInfo.userType,
                    user_score: userInfo.userScore,
                    control: userInfo.control}

      // Send off response
      const response = await axios.post('/user/create/', user);


      return response; 
    }
    catch(error) {
      throw error; 
    }
  }

  /* This function checks whether user is supposed to access page
     Note while a hacker could change the contents of post request, they
     would still not have access to confidential data, because the data itself
     would try and load and would fail authenticating. */
  static async checkUser(component) {
    try {
      const data = {'component' : component};
      const response = await axios.post('/user/check/', data);
  
      return response;
    }
    catch(error) {
      throw error;
    }
  }

  /* This function calls the checkdiary API to see whether the user should
     be redirected to the diary component */
  static async checkDiary() {
    //const auth_token = localStorage.getItem('jwt_access');
    //axios.defaults.headers['Authorization'] = 'JWT ' + auth_token;
    try {
      const response = await axios.post('/user/checkdiary/');

      return response;
    }
    catch(error) {
      throw error;
    }
  }
}

export default UserService;
