export default {
  //HTML DOM IDs
  QUIZ_ID: 'quiz-id',
  QUESTION: {
    CONTAINER_CLASS: 'quiz-question',
    TEXT_CLASS: 'quiz-question-text',
    PLAIN_TEXT: 'Question',
    ANSWERS_CHOSEN_CLASS: 'quiz-answer-chosen-count',
    ANSWERS_CHOSEN_TEXT: 'Answers chosen '
  },
  
  CHOICE: {
    CONTAINER_CLASS: 'quiz-choice',
    TEXT_CLASS: 'quiz-choice-text',
    CHECK_CLASS: 'quiz-choice-check',
    PLAIN_TEXT: 'Choice',
    ON_SELECT_CLASS: 'quiz-choice-selected'
  },

  //text on page
  BUTTON: {
    PLAIN_TEXT: 'button',
  },

  //-----Test values---------
  TEST_Q_NAME: 'This is the quiz name',
  TEST_M_NAME: 'This is the module name ',
};