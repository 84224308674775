import React, { Component } from "react";
import AUTH_STRINGS from '../../strings/auth_strings';

// Component representing an error page. Given a type defined in AUTH_STRINGS,
// it will display a particular error message. Can be expanded on.
class ErrorPage extends Component {

  // Decides what error message to display. 
  // Add more error pages here as cases, if needed, and route to them in app.js
  errorMessage(type) {
    let display;

    switch(type)
    {
      case AUTH_STRINGS.UNAUTHORIZED:
        display = (
          <div>
            <h1>401: Whoops!</h1>
            <h2>You're not meant to be here.</h2>
            <p>
              If you're currently logged in, this means you don't have 
              permission to visit this page. 
              <br/>
              If you're not logged in yet
              , you can <a href="/login">click here</a> to login.
            </p>
          </div>
        );
      break;

      case AUTH_STRINGS.FORBIDDEN:
        display = (
          <div>
            <h1>403: Whoops!</h1>
            <h3>Sorry, but that action was not allowed.</h3>
          </div>
        );
      break;

      // Defaults to an 'Unknown error'
      default: 
        display = (
          <div>
            <h1>Uh oh.</h1>
            <h3>Something went wrong, and we're not sure why.</h3>
          </div>
        );
      break;
    }

    return display;
  }

  render(){
    return (
      <div>
        {this.errorMessage(this.props.errorType)}
      </div>
    );
  }
}

export default ErrorPage;