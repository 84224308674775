import React, { Component } from 'react';
import LogService from '../../services/log.service';
import AUTH_STRINGS from '../../strings/auth_strings';



export default class LogList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:             undefined,
      placeholder:      "Loading",
    };

    this.getLogs = this.getLogs.bind(this);
  }

  async componentDidMount() {
    await this.getLogs();
  }


  async getLogs() {
    try {
      const response = await LogService.getAccessLogs();

      // We have to ensure that if the backend is empty, `this.state.data` must
      // get set to an empty array (not `undefined`)
      if (response.data.length === 0) {
        this.setState({
          data: [],
        });
      } else {
        this.setState({
          data: response.data,
        });
      }

    } catch (error) {
      // This will always get set in the switch statement below
      // I only do it like this to stop the linter from complaining about the
      // switch statement requiring a default case
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.FORBIDDEN;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }

        this.setState({ placeholder: error_msg });
      }
    }
  }

  render() {
    // If `this.state.data` is undefined, then an error must have occurred
    if (this.state.data !== undefined )
    {
      const data = this.state.data;
      /* If data length is 0, that means no student has logged access to the 
         site, so we put out special message */
      if (data.length === 0){
        return(
          <div data-testid="log_empty">
            <b>No recorded accesses</b>
          </div>
        )
      }
      /* Otherwise, we map through the data and print out each location and num
         of accesses */
      return(
        <div data-testid="log_list">
          <b>Student Accesses to the Website</b>
          <ul>
            {data.map((d, ii) => {
              let dataId = 'log_id' + ii;

              return (
                <li key={dataId} id = {dataId}>
                  <b>{d['location']}:</b> {d['num_access']}
                </li>
              );
            })}
          </ul>
        </div>
         
      );
    } else {
      return(
        this.state.placeholder
      );
    }
  }
}
