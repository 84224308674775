import axios from "../http-common";

class BlogService {
  static async getAll() {
    try {
      return await axios.get("/blog/");
    } catch(error) {
      throw error; // Let the caller handle any errors
    }
  }

  static async create(title, body) {
    try {
      const data = {
        title: title,
        body: body,
      };

      return await axios.post("/blog/post/create/", data);
    } catch (error) {
      console.log(error.response);
    }
  }

  static async deletePost(post_id) {
    try {
      const data = {
        id: post_id,
      }

      return await axios.post("/blog/post/delete/", data);
    } catch (error) {
      console.log(error.response);
    }
  }

  static async like(post_id) {
    try {
      const data = {
        post: post_id,
      };

      return await axios.post("/blog/post/like/create/", data);
    } catch (error) {
      console.log(error.response);
    }
  }

  static async unlike(post_id) {
    try {
      const data = {
        post: post_id,
      };

      return await axios.post("/blog/post/like/destroy/", data);
    } catch (error) {
      console.log(error.response);
    }
  }
}

export default BlogService;
