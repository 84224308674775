import React, { Component } from 'react';
import LockService from '../../services/lock.service';
import AUTH_STRINGS from '../../strings/auth_strings';



export default class SelectQuest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quest_list: undefined,
      old_quest: undefined,
      new_quest: undefined,
      placeholder: "Loading",
    };

    this.getQuest = this.getQuest.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.parseData = this.parseData.bind(this);
    this.lockAll = this.lockAll.bind(this);

    
  }

  async componentDidMount() {
    await this.getQuest();
  }

  // This function parses the response data and if it exists identifies the 
  // unlocked questionnaire and returns it.
  async parseData(in_data) {
    let unlock_count = 0;
    let blah = {'questionnaire_name': undefined, 'locked': undefined};
    in_data.map(d => {
      if (d['locked'] === false) {
        blah =  {'id': d['id'], 'questionnaire_name' : d['questionnaire_name'], 'locked': false};
        unlock_count++;
      }
    });

    /* If somehow more than 1 questionnaire is open, just lock them all. Returns
       undefined so that render will have to be called again */
    if (unlock_count > 1) {
      await LockService.lockAll();
      return undefined;
    }
    return blah;  
  }

  // When a radio button is pressed, update new_quest to be id of selected
  // questionnaire
  onValueChange(event) {
    this.setState({
      new_quest: event.target.value
    });
  }

  /* Handles the select form submission. If old_quest exists, i.e there is an
     active questionnaire already, passes the id to lockQuest. Otherwise it 
     passes in -1, which will cause the backend to not try lock any active
     questionnaires, but instead just unlock the one passed in as new_quest*/
  async formSubmit(event) {
    event.preventDefault();
    if(this.state.old_quest['id'] !== undefined) {
      await LockService.lockQuest(this.state.old_quest['id'], this.state.new_quest);
    }
    else {
      await LockService.lockQuest(-1, this.state.new_quest);
    }

    /* Refresh data so component refreshes */ 
    await this.getQuest();
  }

  /* Used to send a lockAll request by the lock all button */
  async lockAll(event) {
    event.preventDefault();
    await LockService.lockAll();

    /* Refresh data so component refreshes */
    await this.getQuest();
  }

  /* Function that queries backend for the list of questionnaires to display */
  async getQuest() {
    try {
      const response = await LockService.getQuest();

      // We have to ensure that if the backend is empty, `this.state.data` must
      // get set to an empty array (not `undefined`)
      if (response.data.length === 0) {
        this.setState({
          quest_list: [],
        }); 
      } else {
        this.setState({
          old_quest: await this.parseData(response.data),
          quest_list: response.data,
        });
      }

    } catch (error) {
      // This will always get set in the switch statement below
      // I only do it like this to stop the linter from complaining about the
      // switch statement requiring a default case
      let error_msg = undefined;
      if (error.response) {
        switch (error.response.status) {
          case 401:
            error_msg = AUTH_STRINGS.UNAUTHORIZED;
            break;
          case 403:
            error_msg = AUTH_STRINGS.FORBIDDEN;
            break;
          default:
            error_msg = AUTH_STRINGS.UNKNOWN_ERROR;
            break;
        }

        this.setState({ placeholder: error_msg });
      }
    }
  }

  render() {
    // If `this.state.quest_list` is undefined, then an error must have occurred
    if (this.state.quest_list !== undefined)
    {
      const list = this.state.quest_list;
      /* If data length is 0, that means no questionnaire exists */
      if (list.length === 0) {
        return(
          <div data-testid="list_empty">
            <b>No questionnaires made</b>
          </div>
        )
      }
      /* Otherwise, we set the active_quest header info and display the rest of 
         the functionality, including the list of questionnaires to unlock */
      let active_quest = "";

      // If we have an already activated questionnaire, mention it
      if (this.state.old_quest !== undefined && this.state.old_quest['questionnaire_name'] !== undefined) {
        active_quest =  "Active Questionnaire: " + this.state.old_quest['questionnaire_name'];
      }
      else {
        active_quest = "No questionnaires are currently active";
      }

      return(
        <div data-testid="list">

          <div data-testid="list_message">
            <b id="active_quest">{active_quest}</b>
          </div>

          <form id="select_form" onSubmit={this.formSubmit}>
            {list.map(d => {
              const id = d['id'];

              return(
                <div key={"mapped_div_" + id}>
                  {/* Yes this == is intentional, it doesn't work with 3 */}
                  <label id={"label_" + id}><input type="radio" id={"radio_" + id} name="unlock" value={id} required checked={this.state.new_quest == id} onChange={this.onValueChange}></input> {d['questionnaire_name']}</label>
                </div>
              );
            })}

            <button id="submit_button" type="submit">
              Activate
            </button>

            <button id="lock_all_button" type="button" onClick={this.lockAll}>
              Lock All Questionnaires
            </button>
          </form>
        </div>
      );
    } else {
      return(
        this.state.placeholder
      );
    }
  }
}
