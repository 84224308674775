import React, { Component } from 'react';

import imageLoader from './utils/image_loader';
import BODY_STRINGS from '../../strings/body_strings';
import './interactive_body_chart.css';
import module_strings from '../../strings/module_strings';

export default class BodyInformation extends Component {

  // Returns the images that need to be displayed based on
  // the selected body part from the chart.
  renderImages(bodyPart)
  {
    let displayedImages;

    // Grab all the png files from the static ibd folder
    let staticImages = imageLoader.importBodyChartImages(this.props.module);

    switch(bodyPart)
    {
      case BODY_STRINGS.HEAD:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_HEAD}>
            <img alt="" src={staticImages['ibd_neck1.png']}></img>
            <img alt="" src={staticImages['ibd_neck2.png']}></img>
            <img alt="" src={staticImages['ibd_neck3.png']}></img>
          </div>
        );
      break;

      case BODY_STRINGS.SHOULDER:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_SHOULDER}>
            <img alt="" src={staticImages['ibd_shoulder1.png']}></img>
            <img alt="" src={staticImages['ibd_shoulder2.png']}></img>
            <img alt="" src={staticImages['ibd_shoulder3.png']}></img>
          </div>
        );
      break;

      case BODY_STRINGS.ARM:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_ARM}>
            <img alt="" src={staticImages['ibd_arm1.png']}></img>
            <img alt="" src={staticImages['ibd_arm2.png']}></img>
            <img alt="" src={staticImages['ibd_arm3.png']}></img>
          </div>
        );
      break;

      case BODY_STRINGS.WAIST:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_WAIST}>
            <img alt="" src={staticImages['ibd_back1.png']}></img>
            <img alt="" src={staticImages['ibd_back2.png']}></img>
            <img alt="" src={staticImages['ibd_back3.png']}></img>
          </div>
        );
      break;

      case BODY_STRINGS.THIGH:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_THIGH}>
            <img alt="" src={staticImages['ibd_hips1.png']}></img>
            <img alt="" src={staticImages['ibd_hips2.png']}></img>
            <img alt="" src={staticImages['ibd_hips3.png']}></img>
          </div>
        );
      break;

      case BODY_STRINGS.ANKLE:
        displayedImages = (
          <div data-testid={BODY_STRINGS.IMAGES_ANKLE}>
            <img alt="" src={staticImages['ibd_foot1.png']}></img>
            <img alt="" src={staticImages['ibd_foot2.png']}></img>
            <img alt="" src={staticImages['ibd_foot3.png']}></img>
          </div>
        );
      break;

      default:
        displayedImages = ( 
          // Display the explanation text for this module's body chart.
          this.renderExplanation()
        );
      break;
    }

    return displayedImages;
  }

  // Depending on the set module type, render a short paragraph of text.
  renderExplanation() {
    let displayText;

    switch(this.props.module) {
      case module_strings.MODULE1:
        displayText = (
          <p>
            In this section, you will find an interactive body chart identifying 
            common areas of technology-related pain, strain, or discomfort. 
            Click a specific part of the body chart to view images of 
            inappropriate postures that can cause pain, strain, or discomfort in 
            that area of the body (e.g. Neck, Shoulder etc).
          </p>
        );
        break;

        case module_strings.MODULE2:
        displayText = (
          <p>
            In this section, you will find an interactive body chart identifying 
            common areas of technology-related pain, strain, or discomfort. 
            Click a specific part of the body chart to view images showing the 
            incorrect set up of the physical environment, which can increase the 
            risk of pain, strain, or discomfort in that area of the body.
          </p>
        );
        break;

        case module_strings.MODULE3:
        displayText = (
          <p>
            In this section, you will find an interactive body chart identifying 
            common areas of technology-related pain, strain, or discomfort. 
            Click a specific part of the body chart to view images of 
            stretches that can reduce the risk of pain, strain, or discomfort 
            in that area of the body.
          </p>
        );
        break;

        default:
          displayText = "";
        break;
    }

    return displayText;
  }

  render() {
    return (
      <div className="cell-info-content" data-testid = {BODY_STRINGS.BODY_INFO}>
        
        {this.renderImages(this.props.selected)}

      </div>
    );
  }
}