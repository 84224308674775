import React, { Component } from 'react';
import BodyChart from './body_chart';
import BodyInformation from './body_information';
import './interactive_body_chart.css';
import BODY_STRINGS from '../../strings/body_strings';
import MODULE_STR from '../../strings/module_strings';

export default class InteractiveBodyChart extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedBodyPart: BODY_STRINGS.DEFAULT,
      module: props.module
    }

    this.changeSelected = this.changeSelected.bind(this);
  }

  // Handler for child components to select a certain body part.
  // Sets the state with a function to get an immediate update.
  changeSelected(bodyPart) {
    this.setState(() => {
      return {selectedBodyPart : bodyPart }
    });
  }

  render() {
    return (
      <div className="ibd-page-grid">
        <div>
          <h1 className="cell-chart-title">
            Interactive Body Chart
          </h1>
          <div className="ibd_page_back">
            {this.props.module === MODULE_STR.MODULE1 &&
              <a href="/module1">Back to module home</a>  
            }
            {this.props.module === MODULE_STR.MODULE2 &&
              <a href="/module2">Back to module home</a>  
            }
            {this.props.module === MODULE_STR.MODULE3 &&
              <a href="/module3">Back to module home</a>  
            }
          </div>
        </div>
        

        {/* Clickable image with different body parts */}
        <BodyChart 
          selected = {this.state.selectedBodyPart} 
          changeSelected = {this.changeSelected}
        />

        {/* Displays the body information based on the selected body part */}
        <div className="cell-info-title">
          <h2 data-testid={BODY_STRINGS.INFO_TITLE}>
            {this.state.selectedBodyPart}
          </h2>
        </div>
        <BodyInformation 
          selected = {this.state.selectedBodyPart} 
          module = {this.state.module}
          changeSelected = {this.changeSelected}
        />
      </div>
    );
  }
}