import React, { Component } from 'react';
import UserCreate from "./user_create";
import UserList from "./user_list";
import LogList from "./log_list";
import SelectQuest from "./select_quest";
import DownloadQuest from './download';
import LockUsers from './lock_users';
import './admin_panel.css';
import DownloadDiary from './diary_download';

class AdminPanel extends Component {
    render() {
        return (
            <div className="admin_panel" data-testid='admin_component'>
                <h1>Admin panel</h1>
                <UserCreate></UserCreate>
                <LogList></LogList>
                <SelectQuest></SelectQuest>
                <DownloadQuest></DownloadQuest>
                <DownloadDiary></DownloadDiary>
                <LockUsers></LockUsers>
                <UserList></UserList>
            </div>
        );
    };
}
export default AdminPanel;