import React from 'react';
import { Link }from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import './home.css';
import STRINGS from '../strings/home_strings';
import AuthHelpers from '../helpers/auth';
import AUTH_STR from '../strings/auth_strings';

import m1Image from "../resources/Home-page/m1-POSTURE.png";
import m2Image from "../resources/Home-page/m2-MY_PHYSICAL_ENVIRONMENT.png";
import m3Image from "../resources/Home-page/m3-STRETCH_AND_MOVE.png";

import Speedlines from "../resources/Home-page/Speedlines.png";

const homeClass = STRINGS.HOME.CLASS;
const modClass = STRINGS.HOME.MODULE_CLASS;
const modContainer = STRINGS.HOME.MODULE_CONTAINER;
const modimage = STRINGS.HOME.MODULE_IMAGE_CLASS;
const modHead = STRINGS.HOME.MODULE_HEADER_CLASS;
const modHeadText = STRINGS.HOME.MODULE_HEADER_TEXT_CLASS;

/* adds background speed lines to header image */
let speedlinesStyle = {
  backgroundImage:
  [ `radial-gradient(rgb(255, 255, 255) 55%, transparent 140%)`,
    `url(${Speedlines})`],
  backgroundSize: "100% 100%"
};

function Home() {
  let display;

  // Decide on how to render the home page.
  // Student, teacher and admin types get the normal page.
  // Questionnaire users get redirected to the questionnaire page.
  if(AuthHelpers.isLoggedIn()) {
    // Switch on user types in case new user types are added
    switch(AuthHelpers.getUserType()) {
      case AUTH_STR.TYPE_QUESTIONNAIRE:
        display = <Redirect to={{ pathname: '/questionnaire' }} />
      break;

      // Student, teacher and admin home pages are the same
      default:
        display = (
          <div className={homeClass}>

            <div className = {modContainer}>

              <div className={modClass}>
                <div className={modHead} style={speedlinesStyle}>
                  <div className={modHeadText}>
                    <Link to="/module1">
                      MODULE 1<br/>
                      <br/>
                      CHANGE MY POSTURE,<br/>
                      RELIEVE MY PAIN!
                    </Link>
                  </div>
                </div>

                <img src={m1Image} className={modimage}/>
              </div>
              
              <div className={modClass}>
                <div className={modHead} style={speedlinesStyle}>
                  <div className={modHeadText}>
                    <Link to="/module2">
                      MODULE 2<br/>
                      <br/>
                      MY PHYSICAL<br/>
                      ENVIRONMENT
                    </Link>
                  </div>
                </div>

                <img src={m2Image} className={modimage}/>
              </div>
              <div className={modClass}>
                <div className={modHead} style={speedlinesStyle}>
                  <div className={modHeadText}>
                    <Link to="/module3">
                      MODULE 3<br/>
                      <br/>
                      STRETCH<br/>
                      AND MOVE!</Link>
                  </div>
                </div>
                <img src={m3Image} className={modimage}/>
              </div>              
            </div>
          </div>
        )
      break;
    }
  }
  else {
    display = <Redirect to={{ pathname: '/login' }} />
  }

  return display;
}

export default Home;
