import React, { Component } from 'react';
import DownloadService from "../../services/download.service";

/* The component holding a button that calls api endpoint to download file. It 
  uses the js-file-download library to easily download the file, because I 
  found that when I implemented permissions, it became tricky to download a file
  So instead this library just lets you get the data normally from the response 
  and it triggers a download itself*/
export default class DownloadDiary extends Component {
  constructor(props) {
    super(props);
    this.download = this.download.bind(this);
  }

  /* Download */
  async download(event) {
    let fileDownload = require('js-file-download');
    const response = await DownloadService.diary_download();
    fileDownload(response.data, "export-diary.csv");
  }

  render() {
    return(
      <div data-testid="diary_download">
        <button id="download_button_diary" onClick={this.download}>
          Download Diary
        </button>
      </div>
    )
  }
    
};