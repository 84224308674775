import game1 from "../../../resources/module_content/module2/game_consoles1.png";
import game2 from "../../../resources/module_content/module2/game_consoles2.png";
import game3 from "../../../resources/module_content/module2/game_consoles3.png";

export default {
  slideContent: [
      {
        id: "slide0",
        title: "1",
        imagePath: game1,
        textBoxes: [
          ["Top of screen must be at or below eye level.", 200.0, 31],
          ["Raise the height of the tablet by placing it on top of a book.", 160.0, 41],
        ],
      },
      {
        id: "slide0",
        title: "1",
        imagePath: game2,
        textBoxes: [
          ["Forearms rested on a pillow", 0.0, 53],
          ["Use books as a footrest if the floor surface is too low.", 40.0, 74],
        ],
      },
      {
        id: "slide0",
        title: "1",
        imagePath: game3,
        textBoxes: [
          ["Forearms rested on a pillow.", 340.0, 53],
          ["Top of screen must be at or below eye level.", 0.0, 76],
          ["Raise the height of the tablet by placing it on top of a book.", 30.0, 86],
        ],
      },
    ],
};