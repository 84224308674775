import axios from "../http-common";

class LogoutService {
  static async logout() {
    try {
      // Logout, blacklist the refresh token
      const response = await axios.post('/logout/',
        {"refresh_token": localStorage.getItem("jwt_refresh")}
      );

      // Remove the tokens from localStorage
      localStorage.removeItem('jwt_access');
      localStorage.removeItem('jwt_refresh');

      // Do this so a page refresh isn't required
      axios.defaults.headers['Authorization'] = null;

      return response;
    } catch (error) {
      // We still remove the tokens from localStorage, but we let the caller
      // deal with the error
      localStorage.removeItem('jwt_access');
      localStorage.removeItem('jwt_refresh');
      throw error;
    }
  }
}

export default LogoutService;
