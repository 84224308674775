import React, { Component } from "react";
import LoginService from '../../services/login.service';
import AuthHelpers from '../../helpers/auth';
import Logo from '../../static/Logo12.png';
import './login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      failed: false, // Used to determine whether login failed
    };

    this.handleChange      = this.handleChange.bind(this);
    this.handleSubmit      = this.handleSubmit.bind(this);
    this.ensureNotLoggedIn = this.ensureNotLoggedIn.bind(this);

    // Ensure the user is not logged in before mounting the component
    this.ensureNotLoggedIn();
  }

  // This function automatically redirects a user to the home page if they load 
  // the Login component while already authenticated
  ensureNotLoggedIn() {
    if (AuthHelpers.isLoggedIn()) {
      window.location.href = '/';
    }
  }

  // Changes the state variables any time a new username / password is entered
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  // Handles actual logins
  async handleSubmit(event) {
    // Prevent the default event handler which refreshes the page on submit
    event.preventDefault();

    try {
      await LoginService.login({
        username: this.state.username,
        password: this.state.password
      });

      window.location.href = '/';
    } catch (error) {
      // An invalid username / password was passed into this method
      this.setState({failed: true});
    }

  }

  render() {
    return(
      <div data-testid="login_component">
        <img alt= "" className="logo" src={Logo}></img>

        <form className="login_form" data-testid="login_form" onSubmit={this.handleSubmit}>
          <div>
            <label data-testid="username_field">
              <input
                placeholder="Username"
                className="login_input"
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.handleChange}
                required
              />
            </label>
          </div>

          <div>
            <label data-testid="password_field">
              <input
                placeholder="Password"
                className="login_input"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
            </label>
          </div>

          { /* Conditionally show the error message */ }
          {this.state.failed === true &&
            <p className="login_error" data-testid="failed_login">
              Invalid username or password.
            </p>
          }

          <input className="login_button" type="submit" value="Login" data-testid="submit"/>
        </form>

        {/* Welcome message */}
        <div className="welcome_message">
          <h2><i>Welcome to TECHgonomics!</i></h2>
          <p>
            Have you ever felt pain or strain in your muscles after
            using a computer? 

            Perhaps, you may have seen your friend playing a video game in an awkward 
            posture? 

            Are you aware of how to set up your physical environment and stretch your 
            muscles correctly when using your smartphone or tablet? 
          </p>
          <p>
            Good news! You will get to learn these from this website! From the 
            interactive quizzes to the discussion blogs, this website will also 
            give you the opportunity to apply and showcase your learning. 
          </p>
          <p>
            It gets better! Score points, challenge your friends, and climb the leaderboard!
          </p>
        </div>

      </div>
    );
  }
}

export default Login;

